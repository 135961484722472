.booking-time-select-modal {

  .modal-wrapper {
    height: 500px;

    @media only screen and (max-width: 640px) {
      height: 85vh;
    }
  }

  div.mini-modal-header {
    border-bottom: 1px solid #eeeeee;
  }

  .booking-time-select {
    margin: 0 20px 0;
  }

  .callemmy-checkbox {
    margin: 0 20px 20px;
  }

  .modal-bottom-buttons {
    padding: 1.2em 0;
  }

  .react-datepicker__portal {
    height: 100%;
    width: 100%;
    overflow: scroll;
  }

}
