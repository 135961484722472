.modal {
  font-family: $font;

  .ion-page {
    justify-content: flex-start;
    height: 100%;
  }

  .modal-header {
    width: calc(100% - 40px);
    border-bottom: 1px solid #eeeeee;
    margin: 0 auto;
    text-align: center;

    @media only screen and (max-width: 640px) {
      padding-top: env(safe-area-inset-top);
    }

    h2 {
      color: $title-color;
      font-family: $font;
      font-size: 18px;
      line-height: 21px;
      font-style: normal;
      font-weight: bold;
      padding: 0.3em 0;
    }
  }

  .modal-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 15px;
  }

  .modal-content {
    color: $text-color;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid #eeeeee;
    width: calc(100% - 40px);
    height: 100%;

    @media only screen and (max-width: 321px) {
      width: calc(100% - 15px);
    }
  }

  .modal-bottom-buttons {
    padding: 1.2em 0;
    margin: auto;
    width: 100%;

    .callemmy-button {
      --color: $primary;
      --border-radius: 6px;
      width: auto;
      height: 34px;
      font-size: 13px;
      margin-right: 0.5em;
      color: white;
      box-sizing: border-box;
      font-style: normal;
      font-weight: bold;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .callemmy-button+.callemmy-button {
      margin-left: 0.5rem;
    }

    .cancel-button {
      --background: white;
      --border-color: #908e9e;
      --border-width: 2px;
      --border-style: solid;
      color: $text-color;
    }

    .secondary-button {
      --background: white;
      color: $text-color;
      text-transform: none;
      text-decoration: underline;
    }
  }
}

.mini {
  .mini-modal-header {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;

    .modal-title {
      text-align: left;
      padding: 16px 20px;

      h2 {
        color: $title-color;
        font-family: $font;
        font-size: 16px;
        line-height: 21px;
        font-style: normal;
        font-weight: bold;
        margin: 0;
      }

      .subtitle {
        margin: 0;
        color: $title-color;
        font-family: $font;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        margin-top: 5px;
      }
    }

    .close-modal {
      background: none;
      border: 0;
      padding: 0;
      width: 56px;
      padding-top: 16px;
      padding-bottom: 16px;

      .callemmy-icon {
        color: #D9D7E0;
        font-size: 16px;
      }
    }
  }

  .modal-wrapper {
    height: 75%;
    border-radius: $border-radius;

    @media only screen and (max-width: 640px) {
      width: 90%;
    }
  }
}

.question-modal {
  @media only screen and (min-width: 640px) {
    .modal-wrapper {
      height: 17.5em !important;
    }
  }
}

.warning-modal-content {
  margin: 40px;

  .warning-modal_text {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 0;
    font-size: 18px;
    height: auto;
  }
}
