.provider-booking-details,
.bookings-detail-page {
  .booking-details_card-header {
    display: flex;
    justify-content: space-between;
  }

  .details_chip {
    margin-top: 0;
    margin-bottom: 0;

    ion-label {
      left: 15%;
    }
  }

  .content_card--content {
    @media only screen and (max-width: 640px) {
      margin-top: 0.5em;
    }

    @media only screen and (min-width: 641px) {
      margin-top: 1em;
    }
  }
}

.booking-detail-details {
  color: #3f3968;
}

.back_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $account-menu-color;
  margin-left: 1em;
  height: 37px;

  &--arrow {
    height: 17px;
    width: 13px;
  }

  &--text {
    color: $account-menu-color;
    font-size: 13px;
    line-height: 21px;
    padding-left: .3em;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: #CCCCCC;
  }
}

.content {
  .bookings-detail-page {
    padding-top: 2.5em;
  }

  .content_card--content {

    .booking-details_card-content {
      .chat {
        position: relative;
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100%;

        @media only screen and (min-width: 641px) {
          margin: 0;
          margin-bottom: 20px;
          height: 40px;
          width: 40%;
        }

        .booker-chat_container {
          background-color: $secondary;
        }

        &_btn {
          --box-shadow: none;
          --font-style: normal;
          --font-weight: bold;
          font-size: 13px;
          --font-size: 13px;
          --line-height: 13px;
          --border-radius: 6px;
          --letter-spacing: 0.5px;
          margin: 0 auto;
          width: 100%;
          height: 34px;
        }

        &_container {
          display: flex;
          justify-content: center;
          position: absolute;
          right: -1.8%;
          bottom: 28px;
          height: 18.67px;
          width: 26px;
          border-radius: 10px;
          background-color: $secondary;

          .chat-messages--num {
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }

    .non-btn {
      border: none;
      background-color: white;
    }

    .show-phone,
    .phone {
      color: $primary;
      font-size: 15px;
      line-height: 18px;
      opacity: 1;
    }

    .show-phone {
      text-decoration: underline;
      text-decoration-color: $primary;
    }

    .no-margin-top {
      margin-top: 0;
    }

    .details_list {
      @include boxed_list;

      .align-start {
        align-self: flex-start;
      }

      .provider-name {
        font-weight: bold;
      }

      .provider-rating-name {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: $account-menu-color;

        @media only screen and (max-width: 641px) {
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
        }

        @media only screen and (max-width: 361px) {
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
        }
      }

      .review-link {
        font-size: 12px;
        color: $secondary;
        width: max-content;
      }

      li {
        @include boxed_li;

        .review-booking-button {
          height: 35px;
          font-size: 13px;
          line-height: 13px;
          --border-radius: 6px;
        }

        .review-booking-button--small {
          font-size: 11px;
          --border-radius: 6px;

          @media only screen and (max-width: 376px) {
            height: 25px;
            margin-top: -5px;
            position: relative;
            right: 20px;
          }
        }
      }

      &--location {
        @include double-line_block;
        margin: 0 0 20px 0;
      }
    }
  }

  .family-info_list {
    @include double-line_block;
  }

  .first {
    margin-top: 0;
  }

  .current-booking_title {
    @include card_title;

    @media only screen and (min-width: 641px) {
      font-size: 18px;
    }
  }

  .cancelation-block {
    margin-top: 25px;

    .cancel-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $label-color;
      --background: #fff;
      --box-shadow: none;

      &_text {
        padding-top: 4px;
      }
    }
  }
}

.cancelation-policy_link {
  font-size: 13px;
  line-height: 20px;
  color: #3f3968;
  margin: 15px auto 10px;

  .underline {
    text-decoration: underline;
  }
}

.provider-rating-row {
  display: flex;
  height: 50.9px;
  text-decoration: none;

  @media only screen and (max-width: 321px) {
    flex-wrap: wrap;
    height: fit-content;
  }

  .avatar-container {
    margin-right: 20px;

    @media only screen and (max-width: 641px) {
      margin-right: 10px;
    }
  }

  .name-rating {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  .provider-rating-name {
    margin-bottom: 5px;
  }

  .rating-reviews {
    display: flex;
    align-items: center;

    &--stars {
      margin-left: 0;
      width: 60%;
      height: 60%;
    }
  }
}

.call_li {
  display: flex;
  align-items: center;

  &--text {
    align-self: center;
  }
}

.subscription-rate--text {
  text-decoration: underline;
}

.provider-booking-details-modals {
  .modal-wrapper {
    height: 380px;
    width: 335px;

    .phone-modal--container {
      display: flex;
      flex-direction: column;
    }

    .phone {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      height: 110.61px;
      width: 303px;
      font-size: 24px;
      line-height: 24px;

      p {
        color: $primary;
        text-decoration-line: underline;
        text-decoration-color: $primary;
      }
    }

    ion-button.callemmy-button.phone-modal_btn {
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      height: 52px;
      width: 295px;
      margin: 10px auto;
    }
  }
}

ion-modal.mini.cancelation-policy_modal {

  .modal-wrapper {
    width: 335px;
  }

  .cancelation-policy_modal--container {
    margin: 12px 21px 0;
    text-align: left;
    color: #3f3968;
    font-size: 13px;
    line-height: 16px;
  }

  .mini-modal-header {
    border-bottom: 1px solid #eeeeee;
  }
}

.dispute-modal_body {
  .dispute-modal_btn {
    margin-top: 120px;
  }
}

.info-box--reconcile {
  margin: 0 0 30px 0;
}

.info-content--reconcile {
  display: flex;
  flex-direction: column;

  p {
    width: 90%;
    margin: 0 0 20px 0;
  }

  .reconcile_button {
    height: 28px;
    width: 90%;
    margin: 0 auto;
    font-size: 13px;
  }

  @media only screen and (max-width: 330px) {
    display: block;
    margin: auto 0;

    .reconcile_button,
    ion-button.og-details-btn-link {
      font-size: 60%;
    }
  }
}