.request-sent {
  overflow: hidden;
  position: relative;
  top: 0;

  &--top {
    height: 30vh;
    color: #FFF;
    background-color: $primary;
    padding-top: 7rem;
    padding-left: 16px;
    padding-right: 16px;

    h2 {
      font-weight: bold;
      font-size: 27px;
      line-height: 21px;
      margin-top: 0;
    }

    &-card {
      text-align: center;
      z-index: 2;
    }
  }

  &_text {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 1rem;
  }

  &_image--desktop {
    width: 20%;
  }

  .callemmy-button,
  .booking-continue,
  .button-inner {
    --border-radius: 6px;
    --box-shadow: none;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 15px;
    text-transform: uppercase;
    height: 52px;
    width: 157px;
  }

  .what-happens-next {
    background-color: #fff;
    color: $primary;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    padding: 10px 16px;

    &_heading {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
    }

    &_list {
      li {
        margin-bottom: .4rem;

        a {
          color: $secondary;
          font-weight: 600;
        }
      }
    }
  }


  .availability-note {
    margin: auto;
    margin-bottom: 3rem;
    margin-top: 1rem;
    width: 80%;
  }
}

@media only screen and (min-width: 640px) {
  .request-sent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    background-image: url('../../images/request-sent_background-desktop.png');
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    background-size: 65%;
    background-color: $account-menu-color;
    min-height: 77vh;
    padding-top: -20px;

    &--top {
      background: 0;
      padding-top: 0;
      display: flex;
      align-items: center;
      margin-left: auto;

      h2 {
        font-size: 36px;
        line-height: 34px;
      }
    }

    &_text {
      font-size: 18px;
      line-height: 21px;
      margin: 2rem auto;
      opacity: 0.8;
      max-width: 345px;
    }

    .what-happens-next {
      background: #FFFFFF;
      box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
      border-radius: 5px;
      padding: 16px 16px;
      margin: auto;
      width: 30%;
      height: fit-content;

      &_heading {
        font-weight: bold;
        font-size: 15px;
        line-height: 16px;
        margin: 3px 0 24px 0;
      }

      &_list {
        font-size: 15px;
        line-height: 18px;
        margin-left: 5%;
      }
    }

    .availability-note {
      position: absolute;
      top: 0;
      margin: auto;
      width: calc(100% - 32px);
    }
  }
}
