.providers {
  background-color: #f1f5f4;
  min-height: calc(100vh - 16.5em);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-bottom: 2em;

  @media only screen and (min-width: 640px) {
    padding: 1em;
  }

  .content {
    @media only screen and (min-width: 640px) {
      flex-direction: row-reverse !important;
      max-width: 100% !important;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.4em;
    margin-bottom: 1.4em;
    flex-wrap: wrap;

    h2 {
      margin: 0;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 21px;
      line-height: 21px;
      color: $primary;
      justify-content: center;
      flex-wrap: wrap;

      @media only screen and (min-width: 640px) {
        font-weight: bold;
        font-size: 36px;
        line-height: 61px;
      }

      .callemmy-button {
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 21px;
        color: $primary;
        height: 30px;
        min-width: 80px;
        max-width: 120px;
        margin-right: 15px;
        --padding-start: 0.5em;
        --padding-end: 0;

        @media only screen and (min-width: 640px) {
          font-weight: bold;
          font-size: 36px;
          line-height: 61px;
          margin-right: 20px;
          height: 40px;
          max-width: 190px;
        }
      }
    }

    .filter-button {
      height: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      --color: #3F3968;
      --border-color: #3F3968;
      --border-style: solid;
      --border-width: 2px;
      --padding-top: 1px;
      --background-hover: #3F3968;
    }

    .callemmy-button-input {
      @media only screen and (min-width: 641px) {
        margin-left: 2em;
      }
    }
  }

  &__filter {
    width: 100%;

    @media only screen and (min-width: 641px) {
      margin-bottom: 1.4em;
    }

    &-content {
      padding: 0em 1em 0em 1em;
      text-align: left;
      display: flex;
      flex-wrap: wrap;

      @media only screen and (max-width: 640px) {
        white-space: nowrap;
      }

      .button-link {
        font-size: 14px;
        color: $text-color;
      }
    }

    .callemmy-chip {
      background: rgba(45, 45, 45, 0.3);

      &__label {
        color: white;
      }

      &__icon {
        font-size: 32px;
        color: white;
        margin-left: 10px;
      }
    }

    .callemmy-button-input {
      .providers-save-shortlist {
        .providers-save-shortlist-title {
          margin: 0;
        }
      }
    }
  }

  .callemmy-infinite-scroll {
    margin-top: 1.5em;
    margin-bottom: 1em;
  }

  .empty-message {
    padding: 2em 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #2D2D2D;
    opacity: 0.8;
  }

  .card-providers-list {
    margin-left: 16px;
    margin-right: 16px;

    .provider-card {
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-top: 0;
      min-width: 15rem;
    }

    @media only screen and (min-width: 640px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .provider-card {
        flex-basis: 32%;
      }
    }

    .providers-list-spinner {
      width: 100%;
      margin-bottom: 20px;
      color: $text-color;
    }
  }
}

.providers-filter-modal {
  .modal-content {
    padding: 0;
  }

  .collapsed-content {
    padding: 25px 0;
    border-bottom: 1px solid #eeeeee;

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $label-color;
    }

    .content-area {
      padding: 20px 0px 0px 10px;

      .callemmy-checkbox {
        margin-bottom: 15px;
      }

      .callemmy-checkbox:last-child {
        margin-bottom: 0;
      }

      .checkbox-label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: $title-color;
      }
    }
  }

  .collapsed-content:last-child {
    border: 0;
  }
}