.callemmy-select {
  ion-label {
    font-family: $font;
    margin-bottom: 1em;
    color: $label-color;
    font-size: 15px;
    height: 15px;
    overflow: unset;
    font-weight: 600;
  }

  .select-input {
    position: relative;
    border: 1px solid $button-border;
    border-radius: 6px;
    padding: 16px 20px;
    display: flex;

    .select-text {
      font-size: 16px;
      color: $title-color;
      width: 100%;
      text-align: left;
      box-sizing: border-box;
    }

    .select-icon {
      display: flex;
      opacity: 0.53;

      .select-icon-inner {
        left: 5px;
        top: 50%;
        margin-top: -3px;
        position: relative;
        width: 0px;
        height: 0px;
        pointer-events: none;
        border-top: 5px solid;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    }

    button {
      left: 0px;
      top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      -webkit-appearance: none;
      border-width: 0px;
      border-style: initial;
      border-color: initial;
      border-image: initial;
      background: transparent;
      outline: none;
      padding: 10px 20px;
    }
  }

  .desktop-select {
    &__control {
      height: 56px;
      border: 1px solid rgb(220, 230, 226);
      background: none;
      width: 100%;
      font-size: 16px;
      color: $text-color;
    }

    &__placeholder {
      color: $title-color;
    }

    &__value-container {
      padding-left: 20px;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding-right: 20px;

      .select-icon {
        display: flex;
        opacity: 0.53;
        color: #737373;

        .select-icon-inner {
          left: 5px;
          top: 50%;
          margin-top: -3px;
          position: relative;
          width: 0px;
          height: 0px;
          pointer-events: none;
          border-top: 5px solid;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
        }
      }
    }
  }
}
