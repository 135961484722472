.payment-confirmation {
  .confirmation-info {
    .service-info,
    .booking-info {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 20px;
        border-bottom: 1px solid #eeeeee;

        span {
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 15px;
          color: rgba(63, 57, 104, 0.5);

          .subscription-rate-tooltip {
            background: none;
            border: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 15px;
            text-decoration: underline;
            color: rgba(63, 57, 104, 0.5);
            margin-left: 5px;
            padding: 0;
          }

          .total-explained {
            font-size: 12px;
            margin-top: 2px;
          }
        }

        span + span {
          opacity: 1;
        }

        .total {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          font-size: 18px;
          line-height: 18px;
        }
      }

      li div.cbk-booking-statement {
        margin-top: 0;
      }

      li:last-child {
        border-bottom: 0;
      }

      .confirmation-total {
        display: block;
      }

      .basic-bundle {
        display: flex;
        justify-content: left;
        text-align: start;
        padding: 20px 0 0 0;
      }
    }

    .location {
      margin-top: 20px;
      padding: 20px;
      border: 1px solid #eeeeee;
      border-radius: 5px;

      p {
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        color: $primary;
        opacity: 0.5;
      }

      ul {
        list-style: none;
        padding: 0;
        margin-top: 15px;
        margin-bottom: 0;

        li {
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          color: $primary;
          padding: 1px 0;
        }
      }
    }
  }

  .credit-card {
    text-align: left;

    .callemmy-label {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      color: $label-color;
      margin-bottom: 1em;
    }

    .callemmy-button {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      text-decoration-line: underline;
      text-decoration-color: #d96452;
      width: 210px;
      margin-bottom: 0;
      height: 18px;
      --color: #d96452;
    }

    .callemmy-button-input {
      .callemmy-button {
        width: 100%;
        height: initial;
        text-decoration-color: $label-color;
      }
    }

    .callemmy-checkbox {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $title-color;
      margin-top: 2em;
      margin-bottom: 1.5em;

      .flexible-checkbox-label {
        margin-left: 20px;
      }

      .primary-card {
        font-size: 13px;
        color: #aaaaaa;
        margin-left: 0.5em;
      }
    }
  }

  .coupon-form {
    .collapsed-content {
      button {
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: #729a8a;
      }
    }

    .coupon-input {
      margin-top: 1.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .callemmy-text-input {
        height: 35px;
        margin-bottom: 0;
        margin-right: 0.5em;
        font-size: 13px;
        line-height: 20px;
      }

      .callemmy-button {
        height: 35px;
        font-size: 13px;
        line-height: 13px;
        --background: #729a8a;
        --color: white;
        --border-radius: 6px;
      }
    }

    .coupon-error {
      color: $error-message-color;
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      margin-top: 0.5em;

      .callemmy-icon {
        color: $error-message-color;
        min-width: 20px;
        width: 20px;
        height: 20px;
        margin-right: 1em;
      }
    }

    .coupons-added {
      margin-top: 1em;

      .coupon-added {
        color: #729a8a;
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        display: flex;

        strong {
          margin-right: 0.5em;
        }

        .remove-coupon {
          margin-left: auto;
          height: 24px;
          --padding-end: 0;
          --padding-start: 0;
          --color: #cccccc;

          .callemmy-icon {
            color: #cccccc;
            font-size: 20px;
          }
        }
      }
    }
  }

  .booking-bottom-buttons {
    .button-link {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #908e9e;
    }
  }

  .purple-payment-info {
    background: #dee2e6;
    border: 1px solid #dee2e6;
    margin-left: 16px;
    width: calc(100% - 74px);
    padding: 20px;

    .payment-info-content {
      padding-left: 16px;

      li {
        padding-left: 10px;
        color: white;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #3f3968;
        margin-bottom: 1em;
      }

      li:last-child {
        margin-bottom: 0;
      }
    }
  }
}
