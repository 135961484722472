.authentication {
  background-color: $content-background-color;

  .authentication-content {
    height: 100%;
    min-height: auto;
    display: flex;
    max-width: 768px;
    padding-top: 0.5em;
    padding-bottom: 2em;

    @media only screen and (max-width: 640px) {
      flex-direction: column;
    }

    @media only screen and (min-width: 641px) {
      margin: auto;
    }
  }

  .page-tab {
    display: flex;
    justify-content: center;

    ion-button {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $primary;
      text-transform: unset;
      height: 40px;
      width: 9em;
      width: 20%;
      margin-right: 0.3em;
    }

    ion-button + ion-button {
      margin-left: 0.3em;
    }

    .page-selected {
      border-bottom: 2px solid $secondary;
      font-weight: bold;
    }
  }
}
