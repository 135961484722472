.card-content {

  .self-report-info_textarea {
    padding-right: 10px;
  }

  .ssn_label {
    text-align: left;
  }

  .acceptance-info {
    a {
      color: $primary;
      font-weight: bold;
      background: none;
      font-size: 14px;
      padding: 0;
      text-decoration-color: $primary;
    }
  }

  span.accept-stripe_checkbox {
    display: flex;
    align-items: center;
    margin-top: 40px;
    text-align: left;

    ion-checkbox {
      margin-top: 2px;
      color: $text-color;
      font-weight: bold;
    }

    ion-label {
      text-align: left;
    }

    @media only screen and (max-width: 640px) {
      align-items: flex-start;
      padding: 0 20px;
    }
  }

  .stripe-checkbox_label {
    color: #908E9E;
    margin-left: 15px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;

    a {
      background: none;
      text-decoration-color: #908E9E;
    }
  }

  .check_img {
    width: 100%;
    height: 100%;

    @media only screen and (min-width: 640px) {
      max-width: 342px;
      max-height: 178.72px;
    }
  }
}
