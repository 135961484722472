.select-options {
  .segment-label {
    font-family: $font;
    margin-bottom: 1em;
    color: $label-color;
    font-size: 15px;
    height: 15px;
    overflow: unset;
    font-weight: 600;
  }

  .list-of-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  button {
    width: 68px;
    height: 62px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid $button-border;
    font-family: $font;
    font-size: 16px;
    margin-bottom: 0.5em;

    @media only screen and (min-width: 641px) {
      width: 23%;
    }
}

  button.selected {
    outline: none;
    border: 2px solid;
    border-color: $secondary;
  }
}
