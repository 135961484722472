#personality--labels {
  display: flex;
  justify-content: space-between;
  max-width: 275px;
  margin: 0 auto;

  ion-label {
    color: $content-background-color;
    margin: 10px;
  }
}

div.booking-bottom-buttons.personality-bottom {
  flex-direction: column;

  .personality--link {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #908e9e;
    border: 0;
    text-decoration: underline;
    text-decoration-color: #3f3968;
    --background: none;
    --color: $text-color;
    --background-activated: transparent;
    --ripple-color: none;
    --box-shadow: none;
    width: calc(100% - 32px);
    display: inline-block;
    text-transform: lowercase;
  }
}

.personality-slide {
  margin: 0 16px;
  background-color: $tertiary;

  .personality-slide-content {
    display: flex;
    min-height: 150px;
    flex-direction: column;
    justify-content: space-around;

    span {
      margin: 30px auto 20px;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    color: $content-background-color;
    font-size: 18px;

    .question-numbered {
      color: $primary;
    }

    .personality--button {
      color: #3f3968;
    }

    .personality-start-button {
      width: auto;
      margin: 0 auto;
    }

    .personality--link {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: $content-background-color;
      border: 0;
      text-decoration: underline;
      text-decoration-color: $content-background-color;
      --background: none;
      --background-hover: none;
      --color: $text-color;
      --box-shadow: none;
      display: inline-block;
      text-transform: lowercase;
    }

    .personality-test-slide-bottom-buttons {
      display: flex;
      flex-direction: column;
      margin-top: 1em;
    }
  }
}