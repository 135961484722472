.bottom-nav-container {
  font-family: $font;
  background-color: $primary;
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 9;

  .nav-buttons-container {
    display: inline-flex;
    width: 100%;
    text-align: center;
    color: $text-color;
    justify-content: space-around;
  }

  button {
    flex-basis: 20%;
    flex-grow: 1;
    flex-shrink: 1;
    background: transparent;
    margin-top: 2%;
    -webkit-appearance: none;
    border: none;
    padding: 0;

    h2 {
      margin: 0.5em;
      color: $text-color;
      text-decoration: none;
      font-family: $font;
    }

    ion-icon {
      color: $text-color;
      width: 20px;
      height: 20px;
    }

    h2 {
      font-size: 10px;
    }
  }

  .empty {
    flex-basis: 10%;
  }

  button:focus,
  button.selected {
    outline: none;

    h2 {
      color: white;
    }

    ion-icon {
      color: white;
    }
  }

  a:active {
    h2 {
      color: white;
    }

    ion-icon {
      color: white;
    }
  }
}
