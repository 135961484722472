.booking-about-family {
  .flexible-checkbox-label {
    color: $title-color;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
  }

  .button-link {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #908E9E;
  }

  .collapsed-content__title {
    width:100%
  }
}
