.shortlists-card {
  color: $text-color;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: left;

  .information-box {
    margin: 1em;
  }

  .shortlist-row {
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #eeeeee;

    ion-button {
      width: 194px;
    }

    .clickable-row {
      width: 100%;
      background-color: transparent;
      border: none;
      padding: 0;
    }

    &--left {
      display: flex;
      align-items: center;
      position: relative;
    }

    &--right {
      .view-booking-button {
        font-size: 13px;
        line-height: 13px;
        width: 153px;
        height: 34px;
      }
    }

    .shortlist-icon-img {
      width: 60px;
      height: auto;
    }

    .shortlist-info {
      width: 80%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      text-align: left;
      font-family: $font;

      @media only screen and (min-width: 640px) {
        margin-right: 1em;
      }

      .shortlist-name {
        color: $primary;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
      }

      .shortlist-members-count {
        color: $title-color;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
      }

      @media only screen and (min-width: 640px) {
        .shortlist-name {
          font-size: 18px;
        }

        .shortlist-members-count {
          font-size: 15px;
        }
      }

      .add-shortlist_input input {
        border-radius: 6px;
        height: 34px;
        width: 100%;
      }
    }
  }

  .shortlist-row:last-child {
    border-bottom: 0;
  }

  .button-link {
    color: #3f3968;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    --padding-start: 0.5em;

    ion-icon {
      color: #3f3968;
      font-size: 24px;
    }
  }

  .create-new-row {
    .add-shortlist {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      margin-left: 0;
      width: 100%;

      .input-button {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;

        .callemmy-button {
          height: 36px;
          width: 50px;
          font-size: 13px;
          margin-left: 0.5em;
          --border-radius: 5px;
          --border-color: #729a81;
          --color: #729a81;
          --background-activated: #729a81;
          --background-activated-opacity: 1;
          --background-focused: #729a81;
          --background-focused-opacity: 1;
          --background-hover: #729a81;
          --background-hover-opacity: 1;
          --color-activated: white;
          --color-focused: white;
          --color-hover: white;
        }

        .callemmy-text-input {
          margin-bottom: 0;
        }
      }
    }
  }
}