.rejection {
  display: flex;
  flex-direction: column;
  background-color: $primary;
  margin-bottom: -4em;
  width: 100%;

  @media only screen and (min-height: 1280px) {
    height: 260vh;
  }

  .rejection--top {
    padding: 30px;
  }

  .logo {
    height: 2.3em;
    width: 7.2em;
    padding: 0 0 20px;
  }

  .rejection-notification-subtitle {
    display: flex;
    align-items: center;
    margin: 15px auto 10px;
    max-width: 302px;

    &_img {
      text-align: left;
      width: 103px;
      height: auto;
    }

    &-text {
      color: $content-background-color;
      font-size: 17px;
      font-weight: bold;
      max-width: 175px;
    }

    @media only screen and (min-width: 641px) {
      justify-content: space-between;
      max-width: 425px;

      &_img {
        width: 155px;
      }

      &-text {
        font-size: 20px;
        max-width: 100%;
      }
    }
  }


  @media only screen and (min-width: 641px) {
    .rejection--top {
      padding-top: 60px;
    }
  }
}

.rejection--title {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  margin: auto;
  width: 92%;

  @media only screen and (min-width: 640px) {
    max-width: 780px;
    margin: 30px auto;
    font-size: 40px;
  }
}

.rejection_card {
  background-color: #524d77;
  margin-top: -1em;
  max-width: 800px;
  align-self: center;
}

.rejection-card_div {
  height: auto;
  line-height: 1.5;
  text-align: left;
  background-color: #524d77;


  @media only screen and (min-width: 640px) {
    max-width: 800px;
  }

  ul {
    margin-top: 0;
    padding-left: 20px;
  }

  p,
  li {
    color: $content-background-color;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .rejection_card-info-box-text {
    margin: auto;
    color: $primary;
    max-width: 425px;

    @media only screen and (min-width: 640px) {
      margin: 40px auto 0;
    }
  }

  .rejection_card-info-box--bold-text {
    font-weight: bold;
  }
}