.provider-card {
  width: 50%;
  display: inline-block;
  height: 360px;
  margin-bottom: 20px;

  @media only screen and (max-width: 640px) {
    width: 100%;
  }

  ion-card-content {
    text-align: center;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;

    ion-avatar,
    .empty-avatar {
      margin: auto;
    }

    .provider-name {
      text-align: center;
      color: $primary;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      margin-top: 1em;
    }

    .reviews-link {
      text-decoration: none;
    }

    .rating {
      justify-content: center;
      margin-top: 1em;

      span {
        font-weight: 500;
      }
    }

    .description {
      margin-top: 1em;
      color: $title-color;
      font-family: $font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      height: 71px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .callemmy-button {
      height: 35px;
      margin: auto;
      margin-top: 1.5em;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      --padding-start: 1.5em;
      --padding-end: 1.5em;

      .callemmy-icon {
        margin-right: 0.5em;
      }
    }

    .shortlist-added {
      margin-top: 1em;
      font-weight: bold;
      color: white;
      --background: #D4D6E0;
    }

    .add-to-other-shortlist {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
      text-align: center;
      text-decoration-line: underline;
      color: #3F3968;
      text-transform: none;
      height: 15px;
      margin-top: 1em;
    }
  }

  &__go-to-profile {
    background: none;
    border: none;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
}
