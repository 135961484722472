.ion-page {
  .tos-modal{
    .modal-bottom-buttons {
      padding-top:0;
    }
  }
}

.modal {
  .tos-modal {
    @media only screen and (min-width: 641px) {
      margin: auto;
      
      .modal-content {
        height: fit-content;
      }
    }

    .terms {
      text-align: left;

      .callemmy-label {
        color: $label-color;
      }
    }

    .modal-bottom-buttons {
      display:flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0;


      @media only screen and (max-width: 640px) {
        height: 150px;
      }
    }

    .read-terms {
      color: #908e9e !important;
      font-size: 13px !important;
      width: 130px !important;
      text-transform: none !important;
      display: inline-flex;
      align-items: center;
      --background: none;
      --background-hover: none;
    }
  }
}

.terms-of-service {
  @media only screen and (min-width: 640px) {
    .modal-wrapper {
      height: 16.5em !important;
      width: 40em !important;
      border-radius: $border-radius;
    }
  }


}
