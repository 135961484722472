:root {
  --ion-color-primary: #e17176;
  --ion-color-primary-rgb: 235, 124, 107;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #cf6d5e;
  --ion-color-primary-tint: #ed897a;

  --ion-color-secondary: #303f5c;
  --ion-color-secondary-rgb: 63, 57, 104;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #37325c;
  --ion-color-secondary-tint: #524d77;

  --ion-color-tertiary: #729a8a;
  --ion-color-tertiary-rgb: 114, 154, 138;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #648879;
  --ion-color-tertiary-tint: #80a496;

  --ion-color-success: #FAD681;
  --ion-color-success-rgb: 250, 214, 129;
  --ion-color-success-contrast: #3F3968;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #dcbc72;
  --ion-color-success-tint: #fbda8e;

  --ion-color-medium: #908e9e;
  --ion-color-medium-rgb: 144, 142, 158;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #7f7d8b;
  --ion-color-medium-tint: #9b99a8;

  --ion-color-purple: #605A8E;
  --ion-color-purple-rgb: 96, 90, 142;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255, 255, 255;
  --ion-color-purple-shade: #544f7d;
  --ion-color-purple-tint: #706b99;

  --ion-color-tertiary-light: #9fb7ad;
  --ion-color-tertiary-light-rgb: 159, 183, 173;
  --ion-color-tertiary-light-contrast: #5e8778;
  --ion-color-tertiary-light-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-light-shade: #8ca198;
  --ion-color-tertiary-light-tint: #a9beb5;

  --ion-color-tertiary-dark: #5e8778;
  --ion-color-tertiary-dark-rgb: 94, 135, 120;
  --ion-color-tertiary-dark-contrast: #fff;
  ;
  --ion-color-tertiary-dark-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-dark-shade: #53776a;
  --ion-color-tertiary-dark-tint: #6e9386;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-font-family: $font;
}

.ion-color-tertiary-dark {
  --ion-color-base: var(--ion-color-tertiary-dark);
  --ion-color-base-rgb: var(--ion-color-tertiary-dark-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-dark-shade);
  --ion-color-tint: var(--ion-color-tertiary-dark-tint);
}

.ion-color-tertiary-light {
  --ion-color-base: var(--ion-color-tertiary-light);
  --ion-color-base-rgb: var(--ion-color-tertiary-light-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-light-shade);
  --ion-color-tint: var(--ion-color-tertiary-light-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

ion-icon {
  color: white;
  border-bottom: none;
}

ion-card-title {
  color: $title-color;
  text-align: left;
  font-size: 16px;
}

ion-card-header {
  border-bottom: 1px solid #eeeeee;
}

ion-card-content {
  padding-top: 20px;
}

ion-label {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  color: $primary;
  align-items: center;
  margin-bottom: 1em;
}

ion-input {
  border: 1px solid $button-border;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 1.5em;
  text-align: left;
}

ion-textarea {
  border: 1px solid $button-border;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 1.5em;
  text-align: left;

  textarea {
    padding-left: 10px !important;
  }
}

ion-checkbox {
  --border-radius: 6px;
}

ion-segment-button {
  outline-style: solid;
  outline-width: thin;
  --color-checked: #eb7c6b;
  --color: $button-border;
}

ion-select {
  --placeholder-opacity: 100%;
}

ion-modal {
  ion-backdrop {
    background: $primary;
    opacity: 0.8 !important;
  }
}

ion-toggle {
  height: 14px;
  width: 26px;
  --handle-width: 12px;
  --handle-height: 12px;
  --background: #ed897a7d;
  --background-checked: #605a8e61;
  --handle-background: #ed897a;
  --handle-background-checked: #605a8e;
  padding-inline: 5px;
}

ion-refresher {
  position: relative;
  height: 0;
}
