.password-recovery {
  background-color: $backdrop-color;
  height: 100%;

  .content {
    height: 100%;
    background-color: $content-background-color;
    padding-top: 1em;
  }

  ion-label {
    color: $label-color;
    text-align: left;
    margin-top: 1.5em;
  }

  ion-input {
    height: 62px;
  }

  ion-button {
    width: 100%;
    justify-self: center;
    height: 62px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  p {
    color: $background-color;
    font-size: 15px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .button-link {
    color: $text-color;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-decoration: underline;
    text-transform: none;
    margin-top: 1em;
  }
}
