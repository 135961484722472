.range-trash-button {
  border: none;
  background: #fff;
}

.range-trash-icon {
  width: 30px;
  height: 30px;
}

.range-day-label {
  color: #ced6d3;
  padding: 5px;
  margin: 0;
}

.range-span {
  color: $label-color;
  padding: 5px;
}

ion-range {
  --knob-size: 25px;
  --bar-height: 10px;
  --bar-background: $button-border;
  --bar-border-radius: 5px;
  --knob-box-shadow: 0px 0px 0px 3px #605A8E;
  --knob-background: #fff;
  padding: 0 0 10px 10px;
}