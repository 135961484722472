.demographics__span {
  color: #3f3968;
  margin-left: 1em;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}

.demographics__checkbox {
  --border-width: 1px;
  --border-color: #989aa2;
}

.demographics__label {
  color: $title-color;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin: .5em;
  color: #3f3968;
}

.demographics__primary {
  font-size: 12px;
  color: #aaaaaa;
}
