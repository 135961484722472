.picker {
  margin-top: 1.5em;
  margin-bottom: 1.5em;

  .callemmy-label {
    font-family: $font;
    margin-bottom: 1em;
    color: $label-color;
    font-size: 15px;
    height: 15px;
    overflow: unset;
    font-weight: 600;
  }

  .select-input {
    position: relative;
    border: 1px solid $button-border;
    border-radius: 6px;
    display: flex;
    padding: 16px 20px;

    .select-text {
      font-size: 16px;
      color: $title-color;
      width: 100%;
      text-align: left;
    }

    .select-icon {
      display: flex;
      opacity: 0.53;

      .select-icon-inner {
        left: 5px;
        top: 50%;
        margin-top: -3px;
        position: relative;
        width: 0px;
        height: 0px;
        pointer-events: none;
        border-top: 5px solid;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    }

    button {
      left: 0px;
      top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      -webkit-appearance: none;
      border-width: 0px;
      border-style: initial;
      border-color: initial;
      border-image: initial;
      background: transparent;
      outline: none;
    }
  }
}

.desktop-picker {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 5;
  }
}

.react-datepicker {
  &__input-time-container {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
}

.react-datepicker-time {
  &__caption {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $label-color;
    padding-top: 2em;
    margin-bottom: 2em;
    margin-left: -10px;
    border-top: 1px solid #EEEEEE;

  }

  &__input {
    margin-left: 0 !important;
    width: 100%;
  }
}

.callemmy-datepicker-calendar {
  font-family: 'Poppins', sans-serif !important;
  border: 0 !important;

  .react-datepicker {
    &__navigation {
      border: 0.7em solid transparent !important;
    }

    &__navigation--next {
      border-left-color: #908E9E !important;
    }

    &__navigation--previous {
      border-right-color: #908E9E !important;
    }

    &__header {
      border-bottom: 0;
    }

    &__current-month {
      position: relative;
      bottom: 5px;
      background: #F2F2F2;
      padding: 5px;
      border-radius: 5px;
      font-style: normal;
      font-weight: bold;
      font-size: 15px !important;
      color: #908E9E;
    }

    &__day-names {
      background: white;
    }

    &__day-name {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #333333;
    }

    &__day, &__day-name {
      line-height: 2rem !important;
      width: 2rem !important;
    }

    &__day {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.05em;
      color: rgba(51, 51, 51, 0.6);

      &--selected {
        border-radius: 50%;
        background-color: $secondary;
        color: white;
      }

      &--selected:hover {
        border-radius: 50%;
        background-color: $secondary;
        color: white;
      }

      &--keyboard-selected {
        border-radius: 50%;
        background-color: $secondary;
        color: white;
      }
    }

    &__day:hover {
      border-radius: 50%;
    }

    &__day--disabled {
      color: #eeeeee !important;
    }
  }
}

.callemmy-datepicker-container {
  background-color: white;
  border-radius: 5px;
  padding: 25px 50px;
  padding-top: 0;

  &__header {
    padding: 25px 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    color: #2D2D2D;
    border-bottom: 1px solid #EEEEEE;
    margin-left: -10px;
    margin-right: -10px;
  }

  &__body {
    padding: 25px 0;

    .date-title {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #908E9E;
      text-align: left;
      margin-left: -10px;
      margin-bottom: 2em;
    }
  }

  .callemmy-datepicker-bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    .confirm {
      border-radius: 6px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #FFFFFF;
      border: 0;
      background-color: $secondary;
      padding: 10px;
      cursor: pointer;
      box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
      margin-left: 5px;
    }

    .cancel {
      margin-right: 5px;
      border: 2px solid #908E9E;
      border-radius: 6px;
      background: none;
      padding: 8px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #908E9E;
      cursor: pointer;
      box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
    }
  }
}

.desktop-time-select {
  width: calc(100% + 10px);
  margin-left: -10px;
}

.react-datepicker__year-select {
  font-family: 'Poppins', sans-serif;
}

#top-datepicker-portal {

  .react-datepicker__tab-loop {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    .react-datepicker-popper {
      position: relative !important;
      transform: none !important;
    }
  }
}
