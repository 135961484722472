.bring-kids-modal {
  div.modal-wrapper {
    height: 412px;
  }
}

.bring-kids-modal_div {
  display: flex;
  flex-direction: column;
  color: $primary;
  padding: 30px 40px;
  font-size: 18px;

  img {
    width: 135px;
  }

  .modal-bottom-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;


    @media only screen and (max-width: 640px) {
      height: 150px;
    }

    .bring-kids-modal-sure-button {
      width: 40%;
    }

    .button-link {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #908E9E;
    }
  }
}

.bold_text {
  font-weight: bold;
}

.can-bring-kids-statement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  img {
    width: 54px;
    height: 54px;
  }

  .statement-text {
    text-align: left;
    padding-left: 20px;
  }
}

.flex-row-align-center {
  display: flex;
  align-items: center;

  .cbk-change-btn {
    margin-top: 23px;
  }
}