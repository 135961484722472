@import '../global/variables';

.entry-page {

  @media only screen and (min-width: 641px) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .page-content {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: $primary;
    box-sizing: border-box;

    .floating-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .floating-card {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    }
  }

  .logo {
    margin-top: 3em;
    width: 180px;


    @media only screen and (min-width: 1024px) {
      width: 204px;
    }
  }

  h1 {
    text-align: center;
    font-style: normal;
    color: white;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;


    @media only screen and (min-width: 1024px) {
      font-size: 42px;
      line-height: 48px;
    }
  }

  h2 {
    text-align: center;
    font-style: normal;
    color: white;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding: 0 55px;

    @media only screen and (min-width: 1024px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .button-select {
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    z-index: 10;

    @media only screen and (max-width: 640px) {
      width: 80%;
      margin-top: 1em;
    }

    @media only screen and (min-width: 1024px) {
      width: 400px;
    }

    .callemmy-button {
      height: 60px;
      width: 100%;
      --border-radius: 6px;
      font-weight: 400;
      text-wrap: wrap;
      font-size: 15px;
      --padding-start: 16px;
      --padding-top: 16px;
      --padding-bottom: 16px;
      --padding-end: 16px;

      @media only screen and (min-width: 1024px) {
        height: 50px;
      }
    }

    .callemmy-button+.callemmy-button {
      margin-top: 1em;
      --background: #BFE5EF;
      --background-activated: #729A8A;
      --background-activated-focused: #729A8A;
      color: $primary;
    }
  }

  .bg-splash {
    position: absolute;
    height: 300px;
    bottom: -4em;
    right: -8em;

    @media only screen and (max-width: 640px) {
      height: 280px;
      left: 0;


      @media only screen and (max-height: 667px) {
        height: 200px;
      }
    }
  }

  .footer {
    bottom: 0;
  }
}
