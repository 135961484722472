.family-settings {
  width: 100%;
  padding-bottom: 2em;

  .content {
    background-color: $content-background-color;
    min-height: calc(100vh - 16.5em);
    padding-top: 0.5em;
    padding-bottom: 2em;
  }

  .information-box {
    margin-bottom: 30px;
  }

  .bottom-buttons {
    display: flex;
    align-items: center;
    margin-top: 2em;
    flex-direction: column;

    .booking-save-changes {
      width: calc(100% - 32px);

      @media only screen and (min-width: 641px) {
        margin-left: 16px;
        margin-right: auto;
        height: 62px;
        width: 300px;
        box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
        display: block;
      }
    }
  }
}
