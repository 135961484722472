.review-sent {
  position: relative;
  background-color: $primary;
  overflow: hidden;

  @media only screen and (min-width: 640px) {
    height: calc(100vh - 117px);
    padding: 0;
  }

  .review-sent-image-div {
    position: absolute;
    text-align: left;
    margin-left: -1em;
    width: 100vw;
    bottom: -175px;

    @media only screen and (max-width: 640px) {
      bottom: -10px;
    }

    @media only screen and (max-height: 655px) {
      bottom: -100px;
    }

    img {
      height: auto;
    }
  }

  &--top {
    padding: 0 2em;
    margin-top: 7em;

    @media only screen and (max-height: 670px) {
      margin-top: 3em;
    }

    @media only screen and (min-height: 1000px) {
      margin-top: 14em;
    }

    h2, p {
      color: #fff;
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      line-height: 28px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
    }

    @media only screen and (min-width: 640px) {
      h2 {
        font-size: 36px;
        line-height: 25px;
      }

      p {
        font-size: 22px;
        line-height: 26px;
      }
    }

    &-card {
      padding: 5em 0 5em 0;
    }
  }

  .booking-continue {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .callemmy-button,
  .booking-continue,
  .button-inner {
    --border-radius: 6px;
    --box-shadow: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 15px;
    text-transform: uppercase;
    height: 52px;
  }

  .share_link {
    margin-left: auto;

    .button-link {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 13px;

      @media only screen and (min-width: 640px) {
        font-size: 15px;
        line-height: 15px;
      }
    }

    ion-icon {
      height: 30px;
    }
  }
}
