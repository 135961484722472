.button-input {
  .button-input-label {
    font-family: $font;
    margin-bottom: 1em;
    color: $label-color;
    font-size: 15px;
    height: 15px;
    overflow: unset;
    font-weight: 600;
  }

  .button-input-link {
    width: 100%;
    text-decoration-color: $label-color;


    .callemmy-icon {
      color: $text-color;
      font-size: 24px;
      opacity: 0.5;
      height: 31px;
      width: 31px;
      margin-right: 0.5em;
      margin-left: -0.5em;
    }

    span {
      margin-left: 0;
      margin-right: auto;
      color: $text-color;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
