.notification-settings-form {
  .notification-label {
    text-align: left;
  }

  .notification-button {
    width: 90%;
    align-self: center;
  }

  hr {
    margin: 2rem 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 0;
  }
}
