.reviews {
  &__list {
    border: 1px solid #EEEEEE;
    border-bottom: 0;
    list-style: none;
    padding-left: 0;

    li {
      border-bottom: 1px solid #EEEEEE;
      margin-left: 0;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .callemmy-rating {
        margin-top: 0;
        justify-content: flex-start;

        span {
          font-weight: 500;
        }
      }
    }
  }

  &__review-top {
    display: flex;
    align-items: center;

    span {
      .callemmy-avatar {
        margin-bottom: 0;
      }
    }
  }

  &__review-info {
    border: 0;
    padding: 0;

    li {
      border: 0;
      padding: 0;
    }

    &--name {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 16px;
      color: $title-color;
      text-align: left;
    }

    &--date {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: $text-color;
      text-align: left;
      margin-top: 1em;
    }
  }

  &__review-text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $title-color;
    text-align: justify;
    margin-top: 20px;
  }

  .reviews__rating {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.profile-rating {
  margin-top: 1em
}