.add-to-shortlist-modal {
  .callemmy-checkbox {
    ion-label {
      color: $title-color;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .shortlist-members-quantity {

    .checkbox-shortlists {
      width: 31px;
      height: 31px;
    }

    .empty-shortlist {
      display: none;
    }

    .checkbox-label-wrap {
      display: flex;
      flex-direction: column;
      background: none;
      border: 0;

      .shortlist-name {
        font-size: 16px;
        color: $title-color;
        margin-bottom: 0;
        margin-left: 1em;
        font-weight: normal;
      }

      .shortlist-quantity {
        font-size: 13px;
        color: $label-color;
        margin-left: 1.2em;
        font-weight: normal;
        margin-bottom: 0;
        margin-top: 3px;
      }
    }

    .callemmy-text-input {
      margin-bottom: 0;
    }

    .create-shortlist-button {
      height: 42px;
      font-size: 13px;
      margin-left: 0.5em;
      --border-radius: 5px;
      --border-color: #729a81;
      --color: #729a81;
      --background-activated: #729a81;
      --background-focused: #729a81;
      --background-hover: #729a81;
      --color-activated: white;
      --color-focused: white;
      --color-hover: white;
    }
  }
}
