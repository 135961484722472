.callemmy-dropdown {
  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  .select-box {
    width: 100%;
    display: flex;
    flex-direction: column;

    ion-label {
      font-family: $font;
      margin-bottom: 1em;
      color: $label-color;
      font-size: 15px;
      height: 15px;
      overflow: unset;
      font-weight: 600;
    }

    ion-select {
      --padding-end: 20px;
      --padding-top: 16px;
      --padding-bottom: 16px;
      --placeholder-color: #2d2d2d;
      font-size: 16px;
      max-width: 100%;
      border: 1px solid $button-border;
      border-radius: 6px;
    }
  }
}
