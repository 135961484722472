@import '../global/variables';

.main-menu {
  --background: $secondary;
  font-family: $font;
  height: 100%;
  overflow-y: auto;
  z-index: 100;

  ion-content {
    background-color: $secondary;
    --padding-top: 25px;
  }

  img {
    display: flex;
    width: 154px;
    height: 48px;
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  ion-label {
    color: $title-color;
    line-height: 13px;
    letter-spacing: .5px;
    font-size: 13px;
    opacity: 0.56;
    padding-left: 20px;
    font-style: normal;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .header-md::after {
    visibility: hidden;
    border: 0;
  }

  .my-callemmy-section {
    ion-item {
      --background-activated: white;
      --color-activated: white;
      --color-hover: white;
      font-size: 21px;
      line-height: 24px;
    }
  }

  .lower-menu-items {
    ion-item {
      --background-activated: white;
      --color-activated: white;
      --color-hover: white;
      font-size: 16px;
      line-height: 19px;
    }
  }

  ion-content {
    --background: $secondary;
    font-family: 'Proxima Nova', sans-serif;

    ion-list {
      background: $secondary;
      margin-bottom: 20px;

      ion-item {
        --background: $secondary;
        --background-activated: white;
        --inner-border-width: 0;
        --color-activated: white;
        --color-hover: white;
        color: white;
        text-decoration: none;
        font-size: 21px;
        line-height: 24px;
        font-weight: 700;
        border: 0;
        -webkit-appearance: none;
        margin-top: -.85rem;

        ion-label {
          height: 1.2rem;
        }
      }
    }
  }
}

.desktop-provider-main-menu {
  min-height: 85vh;
}

.desktop-main-menu {
  max-width: 19em;
  min-width: 16em;
  background-color: $secondary;

  ul {
    padding-left: 0;
    margin-top: 3em;
    padding: $header-footer-padding;

    @media only screen and (max-width: 968px) {
      padding: $small-header-footer-padding;
    }

    li {
      list-style: none;
      text-align: left;

      .callemmy-button {
        color: white;
        text-decoration: none;
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 24px;
        --padding-start: 0;
      }
    }

    .section-title {
      color: $title-color;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      opacity: 0.56;
    }
  }

  ul+ul {
    margin-top: 25px;

    li:first-child {
      margin-bottom: 5px;
    }

    li {
      .callemmy-button {
        height: 25px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
