.account-menu {
  background: #605a8e;
  font-family: $font;
  z-index: 100;
  height: 100%;

  ion-header {
    ion-toolbar {
      margin-top: 1.5em;
      --background: #605a8e;
      --border-width: 0px !important;

      ion-title {
        color: $title-color;
        text-align: left;
        font-size: 13px;
        font-weight: bold;
        padding-left: 20px;
      }
    }
  }

  .header-md::after {
    visibility: hidden;
  }

  ion-content {
    --background: #605a8e;
    font-family: $font;

    ion-list {
      background: $account-menu-color;

      ion-item {
        --background: transparent;
        --background-activated: white;
        --inner-border-width: 0;
        --color-activated: white;
        --color-hover: white;
        color: white;
        text-decoration: none;
        font-size: 21px;
        line-height: 24px;
        display: flex;
        align-items: center;
        font-weight: bold;
        border: 0;
        -webkit-appearance: none;

        ion-menu-toggle {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;

          ion-label {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}