.dashboard {
  .content {
    background-color: $content-background-color;
    min-height: calc(100vh - 16.5em);
    max-width: 100% !important;

    .welcome-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      box-sizing: border-box;
      font-weight: 300;
      font-size: 15px;
      line-height: 21px;

      @media only screen and (max-width: 640px) {
        margin: 0 1rem 1rem 1rem;
      }

      .welcome-card-user {
        font-weight: 500;
      }

      .welcome-card-message {
        .dashboard {
          width: 80px;
          height: 15px;
          font-size: 15px;
          margin-top: -7px;
        }

        .zip {
          width: 55px;
        }
      }
    }

    .bold-text {
      font-weight: bold;
    }

    .card-content {
      p,
      li {
        font-size: 16px;

        @media only screen and (max-width: 640px) {
          font-size: 14px;
        }
      }

      .provider-name {
        text-align: center;
        color: $primary;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin-top: 1em;
      }

      .card-subtitle {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: $title-color;
        text-align: left;
      }

      .card-subtitle--container {
        margin: 30px -20px 0;
        padding: 0 20px;
        padding-top: 25px;
        border-top: 1px solid #eeeeee;
      }

      .description {
        margin: 1em auto 5px;
        color: $title-color;
        font-family: $font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        height: fit-content;

        @media only screen and (min-width: 641px) {
          max-width: 800px;
          margin: 20px auto 5px;
        }
      }

      .title-with-link {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      .provider-profile-reviews--container {
        #reviews {
          margin-top: 25px;
          padding-top: 25px;
          border-top: 1px solid #eee;
        }
      }

      .button--small {
        height: 35px;
        font-size: 13px;
        line-height: 13px;
        --border-radius: 6px;
      }

      .button-link.edit {
        font-size: 13px;
        font-weight: normal;
        height: 18px;
        color: $secondary;
        text-decoration-color: $secondary;
      }

      .button-link.edit.gray {
        color: $text-color;
        text-decoration-color: $text-color;
      }

      .example-image {
        display: inline-block;
        justify-content: center;
        box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
        border-radius: 5px;
        width: fit-content;

        p {
          margin-bottom: 20px;
        }
      }
    }

    .flex-content-container {
      @media only screen and (min-width: 640px) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row-reverse;

        .dashboard-content {
          width: 62%;
        }

        .dash-video,
        .dashboard-content {
          flex: 1;
        }
      }

      .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: calc(100% - 32px);

        .service-card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 47%;
          height: 8rem;
          padding: 15px;
          box-sizing: border-box;
          margin-bottom: 1rem;
          background-color: #ffffff;
          border-radius: 5px;
          border: none;
          box-shadow: 0 4px 9px 0 #3f396812;

          img {
            max-height: 4rem;
            max-width: 6rem;
          }

          span {
            font-weight: 400;
            font-size: 16px;
            color: $primary;
            text-transform: uppercase;
          }
        }
      }
    }
    
    .bottom-content {
      @media only screen and (min-width: 640px) {
        display: flex;
        flex-direction: column;
        width: 65%;
      }
    }

    ion-card-subtitle {
      color: $title-color;
      text-align: left;
      font-size: 16px;
    }

    .marketing-content {
      width: calc(100% - 32px);
      border-radius: 6px;
      margin: auto;

      @media only screen and (min-width: 640px) {
        height: auto;

        video {
          margin-top: 10px;

          .video-no-share-btn {
            margin-top: 25px;
          }
        }
      }

      video {
        width: 100%;
        border-radius: 6px;

        @media only screen and (max-width: 640px) {
          margin-top: 10px;
        }
      }

      img {
        height: 100%;
        width: 100%;
        border-radius: 6px;
      }
    }

    .covid-info-box-container-booker-side,
    .covid-info-box-container-provider-side {
      span {
        line-height: 1.8;
      }
    }

    .filter-button-min {
      width: 45px;
      height: 20px;
      font-size: 8px;
      border-radius: 10px;
      margin-top: -4px;
    }

    .covid-info-box-container-booker-side {
      margin: 1em;
    }

    .covid-info-box-container-provider-side {
      margin: 1em;
    }

    @media only screen and (max-width: 640px) {
      .covid-info-box-container-booker-side {
        margin: 16px 16px;
      }

      .covid-info-box-container-provider-side {
        margin: 16px 16px;
      }
    }
  }

  .bold_text {
    font-weight: bold;
  }
}

.App {
  .page-structure {
    .questionaire-background {
      .questionaire {
        min-height: calc(100vh - 120px);
      }
    }
  }
}

.provider-dashboard {
  .finish-onboarding-no-share-btn {
    margin-bottom: 1.1em;
  }

  .finish-onboarding {
    margin-bottom: 20px;
    margin-top: -10px;

    @media only screen and (min-width: 640px) {
      width: 100%;
    }
  }

  &-content {
    inon-card.welcome_card {
      ion-card-header {
        font-weight: bold;
      }
    }
  }

  .share-profile {
    display: flex;
    width: calc(100% - 32px);
    margin: auto;
    justify-content: flex-end;
  }

  .share-profile-button-link {
    font-size: 15px;
    border: 0;
    text-decoration: none;
    text-decoration-color: $text-color;
    text-transform: none;
    margin-right: 0;
    text-align: right;
    --padding-end: 0;
    --background: none;
    --color: $text-color;
    --background-activated: transparent;
    --ripple-color: none;
    --box-shadow: none;
  }

  .content .content_card--content .details_list {
    .editable-profile_list-item {
      display: inherit;

      span {
        text-align: left;
        margin: 5px 5px 0;
      }

      span + span {
        line-height: 22px;
      }
    }
  }

  .needs_to_verify_identity_button {
    margin-bottom: 20px;
    margin-top: -10px;

    @media only screen and (min-width: 640px) {
      width: 100%;
    }
  }

  .change-notification-card-title {
    font-weight: bold;
    font-size: 14px;
  }
}

@media only screen and (min-width: 640px) {
  .flex-dashboard-content-top-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 30px 0;
    max-height: 60px;
  }
}

.welcome-card {
  text-align: left;
}

.center-img {
  margin: 0 auto 20px;
}

.child-care-modal {
  .modal-wrapper {
    height: 300px !important;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 16px;
    box-sizing: border-box;

    p {
      text-align: left;
    }

    button {
      height: 70px;
      background-color: white;
      border-radius: 6px;
      border: 1px solid $button-border;
      font-family: $font;
      font-size: 19px;
      font-weight: 300;
      margin-bottom: 1em;
    }

    button.selected {
      outline: none;
      border: 2px solid;
      border-color: $secondary;
    }
  }
}
