.provider-schedule {
  .my-notifications-section {
    text-align: left;
  }

  #schedules-notifications-button {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    text-decoration-line: underline;
    text-decoration-color: #eb7c6b;
    min-width: 5.5em;
    --padding-start: 0;
    --padding-end: 0;
    --color: #D96452;
  }
  .content {
    margin-bottom: 2em;
  }

  .provider-schedule-label-head {
    margin-bottom: 2em;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: $primary;
  }

  .availabilities-per-day {
    padding-top: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #EEEEEE;

    .provider-schedule-label {
      color: #ced6d3;
      padding: 5px;
      margin: 0;
    }

    .callemmy-label {
      padding-left: 0;
    }

    .provider-schedule-label {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 16px;
      color: #908E9E;
      margin-bottom: 1em;
      text-transform: capitalize;
    }

    .callemmy-range {
      width: 100%;
    }

    .range-span {
      padding-left: 0;
    }

    .callemmy-button-input {
      margin-top: 1em;
      text-align: left;

      .callemmy-button {
        width: auto;
        --padding-start: 10px;
      }
    }
  }

  .availabilities-per-day:last-child {
    border-bottom: 0;
  }

  .provider-schedule-save {
    width: calc(100% - 32px);

    @media only screen and (min-width: 641px) {
      margin-top: 2em;
      margin-left: 16px;
      height: 62px;
      width: 300px;
      box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
      display: block;
    }
  }
}
