.skills-test-card {
  background-color: $account-menu-color;

  .skills-test-card-content {
    display: flex;
    flex-direction: column;
    color: white;

    span.slide-title {
      font-size: 18px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    div.slide-note {
      margin-bottom: 20px;
    }

    ion-label.slide-answer-style {
      display: inline-block;
      color: white;
      font-weight: normal;
      align-self: center;
    }

    ion-label.slide-answer-style:first-letter {
      text-transform: uppercase;
    }

    .slide-answers-div {
      margin: 20px auto;
      text-align: left;
      max-width: 300px;
    }

    .skills-test-submit-btn,
    .skills-test-start-btn {
      width: 33%;
      margin: 0 auto;
    }

    .skills-test-card-title {
      color: $tertiary;
      font-weight: bold;
      margin: 10px auto 20px;
    }

    span.callemmy-checkbox {
      align-items: flex-start;
    }

    a {
      color: #ea998d;
      text-decoration-color: #ea998d;
    }

    ion-button.skills-test-back-btn {
      text-transform: unset;
      text-decoration-line: underline;
      font-weight: normal;
      font-size: 16px;
      text-underline-position: under;
      --box-shadow: none;
    }

    .bold-text {
      font-weight: bold;
    }

    @media only screen and (max-width: 991px) {
      span.slide-question {
        margin: 0px auto 10px;
        max-width: 332px;
      }

      .slide-answers-div {
        max-width: 220px;
      }
    }
  }
}

.skills-test-ed-modal {
    ion-backdrop {
    background: #ffffff5e;
  }

  .modal-wrapper {
    background-color: transparent;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;
    
    p {
      text-align: left;
    }
    
    padding: 20px 15px;
    border-bottom: none;
    color: white;
  }

  .ion-page {
    height: auto;
    justify-content: center;
    font-size: 16px;

    .modal-body{
      background-color: $account-menu-color;
      height: auto;
      border-radius: $border-radius;
    }

    a {
      color: #ea998d;
      text-decoration-color: #ea998d;
    }
  }
}