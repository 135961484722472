.content {
  .filter-button {
    height: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    --color: #3F3968;
    --border-color: #3F3968;
    --border-style: solid;
    --border-width: 2px;
    --padding-top: 1px;
    --background-hover: #3F3968;
  }

  .shortlists-card {
    margin-top: 1em;
  }

  .flex-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .share_link {
      margin-left: auto;

      .button-link {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 13px;

        @media only screen and (min-width: 640px) {
          font-size: 15px;
          line-height: 15px;
        }
      }

      ion-icon {
        color: black;
        height: 30px;
      }
    }
  }

  .card {
    ion-card-header.shortlist-header {

      .shortlist-detail--subtitle {
        font-size: 13px;
        line-height: 16px;
        text-transform: lowercase;
        color: $title-color;
      }
    }
  }

  .card-footer {
    .cancelation-block {
      margin: 10px 0 15px;
    }

    .cancel-button {
      --background-activated: none;
    }
  }
}

.rating-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 20px;
  border-bottom: 1px solid #eeeeee;
  margin-left: -20px;
  margin-right: -20px;

  @media only screen and (min-width: 640px) {
    height: 73px;
    margin-left: -10px;
  }

  .space-provider-block {
    display: flex;
    align-items: center;
    background: none;
    margin: 0;
    border: 0;
  }

  .provider-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }

  .rating-name {
    margin-bottom: 2px;
    font-size: 15px;
    color: $primary;
    line-height: 21px;

    @media only screen and (min-width: 640px) {
      font-size: 18px;
      margin-bottom: 7px;
    }
  }

  .delete {
    margin-right: 2%;

    button {
      background: none;
      margin: 0;
      border: 0;
    }
  }

  @media only screen and (min-width: 640px) {
    .avatar-image {
      height: 43px;
      width: 43px;
    }

    .delete {
      margin-right: 5%;
    }
  }
}

.top-nav {
  .top-nav-booking_btn--shortlist {
    font-size: 14px;
    margin-top: 54px;
  }
}