.booking-form {
  .content {
    background-color: $content-background-color;
    min-height: calc(100vh - 16.5em);
    padding-top: 0.5em;
    padding-bottom: 2em;

    .form {
      margin: 16px;

      .questionnaire {
        min-height: calc(100vh - 150px);
        border-radius: 5px;
      }
    }
  }
}
