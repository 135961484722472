.providerslist {
  overflow-y: auto;

  .shortlist-member-row {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;

    &__info {
      margin-left: 25px;
      display: flex;
      flex-direction: column;

      .shortlist-member-name {
        text-align: left;
        margin-bottom: 2px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        color: $account-menu-color;
      }

      .shortlist-member-rating {
        text-align: left;
        margin-top: 2px;
      }
    }
  }
}

.providers-list-modal {
  overflow: scroll;

  .shortlist-member-row {
    display: flex;
    margin: 18px;

    &__info {
      padding-left: 20px;
      text-align: left;
    }
  }
}