/* Colors */
$primary: #303f5c;
$secondary: #e17176;

$tertiary: #729a8a;
$background-color: #3f3968;
$content-background-color: #f1f5f4;
$title-color: #2d2d2d;
$text-color: #989aa2;
$info-color: rgba(247, 194, 68, 0.6);
$info-color-desaturate: #FEF8E9;
$text-color-medium: rgba(152, 154, 162, 0.5);
$text-color-desaturate: rgba(152, 154, 162, 0.1);
$button-border: #dce6e2;
$button-hover: #dd5d63;
$label-color: #908e9e;
$account-menu-color: #605A8E;
$backdrop-color: #f2f2f2;
$danger-color: #eb445a;
$error-message-color: #e6391f;

/* Typography */
$font: 'Poppins', sans-serif;

/* Paddings */
$header-footer-right-padding: 5em;
$header-footer-padding: 0 3em;
$small-header-footer-padding: 0 2em;
$small-header-footer-right-padding: 1em;
$footer-padding: 0 9em;

/* Margins */
$card-margin: 16px;

/* Border radius */
$border-radius: 6px;
