ion-avatar {
  height: 50px;
  width: 50px;

}

.empty-avatar {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: #e7f2ef;
}
