.signup {
  background-color: $content-background-color;
  width: 100%;

  .information-box {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 641px) {
    margin-top: 1em;

    .signup-content {

      .flex-signup {
        display: flex;
        flex-grow: 4;
        flex-basis: 60%;
      }

      .social {
        width: 100%;
        max-height: 35%;
      }

      .form {
        width: 100%;

        .show-password {
          margin-bottom: .5em;
        }
      }

      #sign-up__btn--continue {
        margin-bottom: 20px;
      }
    }
  }

  .information-box {
    .info-header {
      justify-content: center;

      ion-card-title {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .content-container {
      .info-content {
        padding: 10px 0;
        font-weight: 500;
        line-height: 19px;
      }

      a {
        font-weight: 600px;
        font-size: 14px;
      }
    }
  }
  
  ion-icon {
    margin-right: 1rem;
  }

  .signup-content {
    background-color: $content-background-color;
    padding-top: 0.5em;
    padding-bottom: 2em;
  }

  .role-tab {
    display: flex;
    justify-content: space-evenly;

    ion-button {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $primary;
      text-transform: unset;
      height: 40px;
      width: 9em;
      padding-top: 15px;
    }

    .role-selected {
      border-bottom: 2px solid $secondary;
      font-weight: bold;
    }
  }

  ion-button {
    font-family: $font;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    height: 60px;

    ion-icon {
      color: $secondary;
    }
  }

  .social {

    ion-button {
      font-size: 15px;
      line-height: 15px;
      --border-width: 2px;
    }

    .social-button {
      border-radius: 6px;
    }

    .google-logo_button {
      color: #000;
      border: thin solid #888;
      box-shadow: 1px 1px 1px grey;
    }

    .fb-logo_button {
      background-color: #1877F2;
    }

    .apple-logo_button {
      color: #fff;
      background-color: #000;
    }

    ion-button+ion-button {
      margin-top: 20px;

      .google-logo {
        height: 25px;
        width: 25px;
        margin-right: 1em;
      }

      .fb-logo {
        width: 25px;
        height: 25px;
        background-color: #1877F2;
        color: #fff;
        z-index: 10;
      }

      .apple-logo {
        width: 25px;
        height: 25px;
        color: #fff;
      }
    }

    ion-card-title {
      color: $title-color;
      text-align: left;
      font-size: 16px;
      font-weight: bold;
    }

    ion-card-content {
      color: $text-color;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      text-align: left;
      min-height: 550px;
    }
  }

  .form {
    height: auto;

    .card {
      background-color: #fff;
    }

    ion-input {
      height: 62px;
    }

    ion-card-title {
      color: $title-color;
      font-weight: bold;
    }

    .terms {
      margin-top: 2em;

      ion-label {
        font-family: $font;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        color: $label-color;
        text-align: left;
        margin-left: 1em;
        margin-top: 0.1em;
      }
    }

    .callemmy-label {
      color: $primary;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
    }

    ion-card {
      margin-bottom: 1.5rem;
    }

    .button-link {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: $text-color;
    }

    .show-password {
      cursor: pointer;
      margin-top: -0.5em;
      margin-bottom: 2em;
      display: flex;
      align-items: center;
      background: none;
      padding: 0;
      border: 0;

      .callemmy-icon {
        color: $label-color;
        font-size: 24px;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-decoration: underline;
        color: $label-color;
      }
    }
  }
}

div.content {
  @media only screen and (max-width: 640px) {
    .signup-content {
      padding-top: 0;

      ion-input {
        height: 45px;
        margin-bottom: 1em;
      }

      ion-label {
        margin-bottom: .5em;
      }

      ion-button {
        font-size: 18px;
        line-height: 18px;
        height: 45px;
        margin-bottom: 20px;
      }

      .show-password {
        margin-bottom: .8em;
      }
    }
  }
}

.access-container {
  text-align: left;
  margin: 1em 0;

  .access-switch {
    font-size: 12px;
    color: $secondary !important;
    letter-spacing: -0.18px;
    font-weight: 600;
  }
}
