@mixin single-box {
  margin: 20px;
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}

@mixin double-line_block {
  @include single-box;

  p {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    color: $primary;
    opacity: 0.5;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 0;

    li {
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: $primary;
      padding: 1px 0;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin date_container {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: $tertiary;
}

@mixin date-day {
  align-self: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

@mixin date-month {
  align-self: center;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.5px;
  margin: 0;
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
}


@mixin date_box {
  border: 2px solid $tertiary;
  box-sizing: border-box;
  border-radius: 4px;
  width: 59px;
  height: 56px;

  &-container {
    @include date_container;
  }

  &--day {
    @include date-day;
  }

  &--month {
    @include date-month;
  }
}

@mixin centerHorizantalVertical {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin boxed_list {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0;
  list-style: none;

  li:last-child {
    border-bottom: none;
  }
}

@mixin boxed_li {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #eeeeee;

  span {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: rgba(63, 57, 104, 0.5);

    @media only screen and (min-width: 641px) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  span+span {
    opacity: 1;
    color: $primary;
    text-align: right;
  }
}

@mixin card_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: $title-color;
}