.phone-verification_input_button--container {
  margin-top: 20px;

  .callemmy-text-input {
    height: 36px;
  }
  @media only screen and (min-width: 640px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .callemmy-text-input {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }

}

.phone-verification_info_box {
  margin-top: 0px;
}

.phone-container {
  .button-link {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #908E9E;
  }
}
