.allow-modal {

  .modal-wrapper {
    height: 31em !important;

    @media only screen and (min-width: 641px) {
      width: 25em !important;
    }
  }
}

.allow-alert {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;

  .callemmy-icon {
    font-size: 104px;
    color: $secondary;
    margin: 0 auto;
  }

  &__title {
    font-size: 18px;
    color: $title-color;
  }

  &__text {
    font-size: 18px;
    color: $title-color;
  }

  &__confirm {
    width: 100%;
    --border-radius: 6px;
  }

  &__cancel {
    width: 100%;
    color: $text-color;
    font-weight: normal !important;
  }
}
