.ion-page {
  .modal-urgent-notifications {
    --height: auto;

    .modal-wrapper {
      height: auto !important;

      @media only screen and (min-width: 641px) {
        width: auto;
      }

      .ion-page {
        position: relative;
        contain: content;
        max-height: 85vh;

        .modal-content {
          overflow: auto;
        }
      }
    }

    .user-notification {
      .modal-content {
        color: $title-color;

        .modal-form {
          margin-top: 25px;
          text-align: center;
        }
      }

      .modal-bottom-buttons {
        .notification-modal-button-primary {
          --background: #eb7c6b;
        }

        .notification-modal-button-secondary {
          --background: white;
          --border-color: #908e9e;
          --border-width: 2px;
          --border-style: solid;
          color: $text-color;
        }

        .notification-modal-button-tertiary {
          --background: #729a8a;
        }

        .notification-modal-button-link {
          width: auto;
          border: 0;
          text-decoration: underline;
          text-decoration-color: $text-color;
          text-transform: none;
          color: $text-color;
          --background: none;
          --color: $text-color;
          --background-activated: transparent;
          --ripple-color: none;
          --box-shadow: none;
        }
      }

      .modal-bottom-buttons-booking-concluded {
        display: block;
        margin-top: 15px;

        .notification-modal-button-booking-concluded-primary {
          font-size: 13px;
        }

        .notification-modal-button-booking-concluded-link {
          border: 0;
          font-size: 13px;
          text-decoration: underline;
          text-decoration-color: $text-color;
          text-transform: none;
          color: $text-color;
          --background: none;
          --color: $text-color;
          --background-activated: transparent;
          --ripple-color: none;
          --box-shadow: none;
        }
      }
    }
  }
}
