.service-option {
  font-family: $font;
  outline: none;

  .service-basic-description {
    margin: 32px;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    color: $primary;
    text-align: left;
  }

  .service-title,
  .service-description {
    margin-bottom: 16px;
    line-height: 19px;
    font-weight: 300;
    text-align: left;
  }

  .service-title {
    font-weight: 500;
  }

  .service-extra {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .service-price {
      font-weight: 500;
    }

    .service-button {
      width: 6rem;
    }
  }
}
