.birthday-picker {
  margin-top: 1.5em;
  margin-bottom: 1.5em;

  .callemmy-label {
    font-family: $font;
    margin-bottom: 1em;
    color: $label-color;
    font-size: 15px;
    height: 15px;
    overflow: unset;
    font-weight: 600;
  }

  .select-input {
    position: relative;
    border: 1px solid $button-border;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 62px;
    box-sizing: border-box;

    .custom-birthday-picker {
      padding: 16px 20px;
    }

    .select-text {
      font-size: 16px;
      color: $title-color;
      width: 100%;
      text-align: left;
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
    }

    .select-icon {
      position: absolute;
      right: 20px;
      display: flex;
      opacity: 0.53;

      .select-icon-inner {
        left: 5px;
        top: 50%;
        margin-top: -3px;
        position: relative;
        width: 0px;
        height: 0px;
        pointer-events: none;
        border-top: 5px solid;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    }
  }
}

.desktop-picker {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 5;
  }
}

.react-datepicker__year-dropdown-container {
  height: 3em;
}

.react-datepicker__year-select {
  height: 2em;
  width: 100px;
}
