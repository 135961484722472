.detail_modal {

  .modal-wrapper {

    div.mini-modal-header {
      border-bottom: none;
    }

    height: 450px !important;

    @media only screen and (min-width: 641px) {
      width: 400px !important;
    }
  }

  .detail-modal--content {
    padding: 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .callemmy-icon {
      font-size: 104px;
      color: $primary;
      margin: 0 auto;
    }

    &-callemmy-logo {
      height: 104px;
      width: auto;
    }

    &-title {
      font-size: 18px;
      color: $primary;
    }

    &-text {
      font-size: 18px;
      color: $primary;
    }
  }


}
