.address-create {
  .callemmy-label {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: $label-color;
  }

  .callemmy-text-input {
    height: 62px;
  }

  .search-address {
    position: relative;

    .search-bar {
      padding: 0;
      border: 1px solid $button-border;
      box-sizing: border-box;
      border-radius: 6px;
      margin-bottom: 1.5em;
      text-align: left;
      --box-shadow: none;
      --background: inherit;

      .searchbar-input {
        padding-inline-start: 45px;
        padding-inline-end: 25px;
      }

      .searchbar-search-icon {
        left: 12px;
      }
    }

    .address-list {
      position: absolute;
      top: 3.9em;
      width: 100%;
      z-index: 3;
    }
  }

  .inputs-block {
    display: flex;

    .half-input {
      width: 45%;
      display: inline-block;

      .select-input {
        @media only screen and (max-width: 640px) {
          padding: 20px 20px;
        }
      }
    }

    .half-input + .half-input {
      margin-left: 10%;
    }
  }
}
