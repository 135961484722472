@import './global/variables';
@import './global/mixins.scss';
@import './global/ionic';
@import './components/map';
@import './components/toast';
@import './components/reconcile-modal';
@import './components/update-provider-info-modal';
@import './components/update-app-page';
@import './components/zip-code-modal';
@import './components/notifications';
@import './components/change-phone-number-modal';
@import './components/reviews';
@import './components/chat';
@import './components/cancel-modal';
@import './components/error-page';
@import './components/question-providers';
@import './components/allow-alert';
@import './components/marker';
@import './components/tooltip';
@import './components/payment';
@import './components/providers-list';
@import './components/idv-questions';
@import './components/birthday-picker';
@import './components/add-credit-card';
@import './components/terms-of-service';
@import './components/error-message';
@import './components/top-nav';
@import './components/footer';
@import './components/dashboard';
@import './components/booking';
@import './components/who-to-book';
@import './components/booking-details';
@import './components/about-family';
@import './components/rating';
@import './components/provider-card';
@import './components/bottom-nav';
@import './components/information-box';
@import './components/option-box';
@import './components/drop-down';
@import './components/card';
@import './components/date-picker';
@import './components/button-input';
@import './components/text-area';
@import './components/collapsed-content';
@import './components/signin';
@import './components/signup';
@import './components/account-menu.scss';
@import './components/modal.scss';
@import './components/popover.scss';
@import './components/checkbox.scss';
@import './components/bookings-list.scss';
@import './components/shortlists-card.scss';
@import './components/avatar.scss';
@import './components/text-input.scss';
@import './components/password-recovery.scss';
@import './components/main-menu.scss';
@import './components/my-details.scss';
@import './components/authentication.scss';
@import './components/address-manager.scss';
@import './components/address-create.scss';
@import './components/select.scss';
@import './components/family-settings.scss';
@import './components/credit-card-manager.scss';
@import './components/change-password.scss';
@import './components/about-you.scss';
@import './components/request-sent.scss';
@import './components/subscription.scss';
@import './components/providers.scss';
@import './components/add-to-shortlist-modal.scss';
@import './components/provider-bookings.scss';
@import './components/date-display.scss';
@import './components/chip.scss';
@import './components/provider-profile.scss';
@import './components/bookings-details.scss';
@import './components/entry-page.scss';
@import './components/onboarding-ach.scss';
@import './components/onboarding-demographics.scss';
@import './components/onboarding-more-about-you.scss';
@import './components/onboarding-super-powers.scss';
@import './components/onboarding-references.scss';
@import './components/onboarding-phone-number.scss';
@import './components/onboarding-create-profile.scss';
@import './components/onboarding-identity-verification.scss';
@import './components/shortlist-detail.scss';
@import './components/booking-time-select-modal.scss';
@import './components/rejection.scss';
@import './components/provider-schedule.scss';
@import './components/review-modal.scss';
@import './components/range.scss';
@import './components/provider-opportunity.scss';
@import './components/review-sent.scss';
@import './components/notification-settings-form.scss';
@import './components/review-modal.scss';
@import './components/share-modal.scss';
@import './components/payout-info-modal.scss';
@import './components/detail-modal.scss';
@import './components/onboarding-skills.scss';
@import './components/onboarding-schedule.scss';
@import './components/personality-test.scss';
@import './components/no-show.scss';
@import './components/bring-kids-modal.scss';
@import './components/add-to-calendar.scss';
@import './components/hear-about-us-modal.scss';
@import './components/skills-test.scss';
@import './components/service-option.scss';
@import './components/booking-form.scss';
@import './components/bookin-shortcut.scss';
@import './Fonts.scss';
@import './UnderConstructionPage.scss';

body {
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

a:visited {
  color: inherit;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background-color: $primary;

  .callemmy-content {
    --background: #f1f5f4;

    @media only screen and (max-width: 640px) {
      --padding-top: env(safe-area-inset-top);
    }
  }

  .callemmy-content-splash-page-padding-top {
    @media only screen and (max-width: 640px) {
      --padding-top: 0;
      ;
    }
  }

  .content-with-padding-top {
    @media only screen and (max-width: 640px) {
      --padding-top: 190px;
    }
  }

  .page-structure {
    @media only screen and (max-width: 640px) {
      padding-bottom: 4.25em;

      .full-page {
        position: fixed;
        background: $primary;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
      }
    }
  }

  @media only screen and (min-width: 641px) {
    .page-structure {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      background-color: $content-background-color;

      .desktop-main-menu {
        flex-shrink: 4;
        flex-basis: 30%;
      }

      section {
        flex-grow: 4;
        flex-basis: 60%;
      }

      .full-page {
        padding-left: 0;
        padding-right: 0;
      }

      .footer {
        flex: 4 1 100%;
      }
    }
  }

  .container {
    padding-left: 1em;
    padding-right: 1em;
  }

  .half-top {
    @media only screen and (max-width: 640px) {
      position: relative;
      margin-top: -7em;
      --margin-top: -7em;
      z-index: 3;
      padding-bottom: 3.8em !important;
      --padding-bottom: 3.8em !important;
    }
  }

  .content {
    background-color: $content-background-color;
    min-height: calc(100vh - 16.5em);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding-bottom: 2em;

    @media only screen and (min-width: 640px) {
      max-width: 60%;
      padding: 1em;
    }
  }

  .right-side-desktop {
    @media only screen and (min-width: 640px) {
      display: flex;
      flex-direction: column;
      background-color: $content-background-color;
      min-width: 220px;
      width: 35%;
    }
  }

  .left-side-desktop {
    width: 100%;

    @media only screen and (min-width: 640px) {
      width: 65%;
    }
  }

  .button-link {
    border: 0;
    text-decoration: underline;
    text-decoration-color: $text-color;
    text-transform: none;
    --background: none;
    --color: $text-color;
    --background-activated: transparent;
    --ripple-color: none;
    --box-shadow: none;
  }

  ion-button {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-inline-start: 0;
  }

  .input-label {
    font-family: $font;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $label-color;
  }

  .card-separator {
    background-color: #eeeeee;
    height: 1px;
    width: calc(100% + 34px);
    margin-left: -17px;
    margin-top: 2em;
    margin-bottom: 2em;
    border: 0;
  }

  .card-separator-with-margin {
    width: 100%;
    margin-left: 0;
    background-color: #eeeeee;
    height: 1px;
    margin-top: 2em;
    margin-bottom: 2em;
    border: 0;
  }

  .mobile-only {
    @media only screen and (min-width: 641px) {
      display: none;
    }
  }

  .desktop-only {
    @media only screen and (max-width: 640px) {
      display: none;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-capitalize {
  text-transform: capitalize;
}
