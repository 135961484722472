.under-construction {
  background-color: #BFE5EF;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;

  .pills {
    position: absolute;
    top: 0;
    left: 21.303vw;
    width: 9vw;
  }

  .crossedLines {
    position: absolute;
    top: 19.912vw;
    left: 24.012vw;
    width: 13.69vw;
  }

  .curlyLines {
    position: absolute;
    top: 19.912vw;
    left: 63.375vw;
    width: 16.201vw;
  }

  .under-construction-head {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 32.439vw;
  }

  &__title {
    font-family: 'Martel Heavy';
    font-size: 3.543vw;
    color: #2f3aa3;
  }

  &__body {
    font-family: 'Poppins';
    color: #303f5c;
    font-size: 1.354vw;

    a {
      text-decoration: none;
      color: #303f5c;
    }
  }

  .footer-combo {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .magic-wand {
      position: absolute;
      left: 32.43vw;
      width: 36.53vw;
      z-index: 1;
    }
  
    .wave {
      display: block;
      width: 92.753vw;
      left: 5.857vw;
    }

    .waveMobile {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    padding-top: 11vw;

    .pills {
      top: 24.481vw;
      left: 11.481vw;
      width: 13.519vw;
    }

    .crossedLines {
      top: 79.944vw;
      left: 21.111vw;
      width: 25vw;
    }
  
    .curlyLines {
      top: 74.389vw;
      left: 60.481vw;
      width: 29.012vw;
    }

    &__title {
      font-size: 5.324vw;
    }

    &__body {
      font-size: 5.509vw;
      width: 59vw;
    }

    .footer-combo {
      .wave {
        display: none;
      }

      .waveMobile {
        display: block;
        width: 100vw;
      }

      .magic-wand {
        left: auto;
        width: 60.37vw;
        bottom: 25.37vw;
      }
    }
  }
}