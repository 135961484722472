.callemmy-map {
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 0.5rem;

    a {
      color: $primary;
    }
  }
}
