.top-nav, .top-nav-web {
  ion-icon {
    font-size: 24px !important;
    --ionicon-stroke-width: 40px;
  }

  .title {
    font-family: $font;
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin: auto;
  }

  .left-menu-button {
    margin-left: 1em;
  }

  .right-menu-button {
    margin-right: 1em;
  }
}

.top-nav-buttons {
  position: relative;
  z-index: 11;
  margin: auto;
  width: 100%;
  transition: 0.1s;

  .top-nav-booking-button {
    height: 43px;
    width: calc(100% - 32px);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    z-index: 10;
    transition: 0.1s;
    --border-radius: 6px;
  }

  .copy-to-my-callemmy-button {
    color: white;
  }
}

.top-nav--mobile {
  height: 180px;
}

.top-nav--mobile {
  height: 180px;
}

.authenticated-desktop-top-nav {
  height: auto !important;
  min-height: 109px;
}

.desktop-top-nav {
  height: 15em;
  max-height: 256px;
  width: 100%;
  background-color: $primary;

  .desktop-top-nav-container {
    display: flex;
    padding: $header-footer-padding;
    padding-bottom: 1.75em;
    padding-top: 1.75em;

    @media only screen and (max-width: 968px) {
      padding: $small-header-footer-padding;
      padding-top: 1.75em;
      padding-bottom: 1.75em;
    }
  }

  .desktop-logo {
    width: 180px;
    height: 56px;
    object-fit: contain;
  }

  .menu-links {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    min-width: 408px;

    a {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      text-decoration: none;
      color: white;
    }

    a + a {
      margin-left: 1em;
    }

    a:visited {
      color: white;
    }

    .authentication-buttons {
      margin-left: 2em;
      width: 90px;
      height: 34px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .authentication-buttons + .authentication-buttons {
      margin-left: 1em;
    }

    .desktop-signup {
      color: white;
      --border-color: white;
    }

    .desktop-my-account-button {
      margin-left: 2em;
      margin-right: 1em;
      --border-width: 0;
      --padding-end: 0;
      --padding-start: 0;
      --background-hover: transparent;
      --ripple-color: transparent;

      .callemmy-icon {
        font-size: 25px;
      }
    }

    .desktop-menu-button {
      margin-right: 0;
      --border-width: 0;
      --background: none;
      --box-shadow: none;
      --padding-end: 0;
      --padding-start: 0;
      --background-hover: transparent;
      --ripple-color: transparent;

      .callemmy-icon {
        font-size: 30px;
      }
    }
  }

  .page-title {
    h1 {
      color: white;
      font-style: normal;
      font-weight: bold;
      font-size: 41px;
    }
  }

  .page-title-with-button {
    height: 62px;
    background: #605a8e;
    box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $header-footer-padding;

    @media only screen and (max-width: 968px) {
      padding: $small-header-footer-padding;
    }

    h1 {
      color: white;
      font-weight: bold;
      font-size: 21px;
      display: flex;
      align-items: center;
    }

    .desktop-make-booking {
      --box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
      --border-radius: 6px;
      --color: white;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.5px;
    }
  }

  .desktop-my-account-menu {
    padding: 0;
    list-style: none;
    text-align: left;
    width: 15em;

    li.title {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $title-color;
      opacity: 0.56;
      margin-bottom: 0px;
      margin-top: 2em;
    }

    li {
      margin-bottom: 10px;
      height: 25px;

      .callemmy-button {
        height: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $secondary;
        text-decoration: none;
        --padding-start: 0;
      }
    }
  }
}

.callemmy-page {
  .top-nav-web {
    ion-buttons {
      height: 100%;
    }
  }
}
