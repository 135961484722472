.payout-info-modal {
  .stripe-input-label {
    text-transform: none;
  }
  span.accept-stripe_checkbox {
    display: flex;
    align-items: center;
    margin-top: 40px;
    text-align: left;

    ion-checkbox {
      margin-top: 2px;
      color: $text-color;
      font-weight: bold;
    }

    ion-label {
      text-align: left;
    }

    @media only screen and (max-width: 640px) {
      align-items: flex-start;
      padding: 0 20px;
    }
  }
  .stripe-checkbox_label {
    color: #908e9e;
    margin-left: 15px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;

    a {
      background: none;
      text-decoration-color: #908e9e;
    }
  }
  button.question-button {
    margin-left: 1em;
    border: none;
    background-color: white;
  }
  @media only screen and (min-width: 640px) {
    .react-datepicker__portal {
      width: 600px;
      height: 500px;
    }
  }
}

.payout-info-modal,
.payout-info-modal-wrapper {
  .payout-info-modal {
    height: 100%;
    display: flex;
    flex-direction: column;

    .modal-content {
      p {
        text-align: left;
        margin-top: 0;
        margin-bottom: 1.5em;
        line-height: 22px;
      }

      .callemmy-select {
        width: 100%;
      }

      .payout-finish-onboarding-info {
        margin-bottom: 2em;
      }
    }

    .modal-bottom-buttons {
      padding: 1em 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .apply-button {
        width: auto;
      }

      .close-modal {
        color: $text-color;
        text-transform: capitalize;
      }
    }
  }
}

.payout-info-modal-wrapper {
  .information-box {
    margin-top: 0;
  }

  .callemmy-text-area {
    margin-top: 30px;
    width: 100%;

    .textarea-element {
      margin-bottom: 0;
    }
  }
}
