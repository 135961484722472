.review-modal {

  .review-icon-button {
    background: none;
    margin: 0;
    border: none;
  }

  .modal-wrapper {
    height: 580px !important;
  }

  .review-booking-modal {
    display: flex;
    flex-direction: column;

    .modal-content {
      flex-grow: 3;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: space-evenly;

      .callemmy-select {
        width: 100%;
      }

      .information-box {
        margin-bottom: 0;
      }

      .your-rating-label, .your-comments-label {
        margin-top: 30px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: $text-color;
      }

      .rating-stars {
        margin-top: 30px;

        .callemmy-icon {
          color: #D4D6E0 !important;
          font-size: 42px !important;
          --ionicon-stroke-width: 14px;
          --ionicon-stroke-color: #D4D6E0;
        }
      }

      .callemmy-text-area {
        margin-top: 20px;
      }
    }

    .review-confirm-button {
      // margin-top: 15px;
      height: 52px;
      width: auto;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }
}


