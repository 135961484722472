.provider-onboarding-iframe {
  width: 95%;
  height: 600px;
}

h2.margin-bottom {
  margin-bottom: 20px;
}

#typeform-skills {
  @media only screen and (min-height: 380px) {
    height: 120vh;
  }

  @media only screen and (min-height: 640px) {
    height: 80vh;
  }

  @media only screen and (min-height: 1024px) {
    height: 60vh;
  }
}
