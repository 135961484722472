.modal {

  .zip-code-modal {
    .modal-content {
      border-bottom: 0;

      p {
        color: $primary;
        font-size: 18px;
      }
    }

    .modal-bottom-buttons {
      .sub-action {
        width: 40%;
        margin: auto 0;
        color: #989aa2;
        background: transparent;
        margin-top: 10px;
      }
    }
  }
}


.zip-modal {
  .modal-wrapper {
    height: 355px !important;
  }
}

.icon-link-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  ion-icon {
    margin-left: 5px;
    height: 34px;
  }
}