.provider-reconcile-modal {
  height: auto;

  .reconcile-modal {
    .callemmy-picker {
      width: 100%;
    }

    .modal-bottom-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      .callemmy-button {
        width: auto;
      }

      .close-modal {
        color: $text-color;
        text-transform: capitalize;
      }
    }
  }
}
