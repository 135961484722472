.booking {
  background-color: $content-background-color;
  padding: 1em 0;

  .content {
    background-color: $content-background-color;
    min-height: calc(100vh - 16.5em);
    padding-top: 0.5em;
    padding-bottom: 2em;

    .booking-error-message {
      width: calc(100% - 32px);
      padding: 0 1em;

      @media only screen and (max-width: 640px) {
        margin-top: 0;
      }
    }
  }

  .steps {
    text-transform: uppercase;
    color: $secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  h1 {
    color: $primary;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 21px;
  }

  .callemmy-text-input {
    height: 62px;
    color: $title-color;
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 20px;
    --padding-end: 20px;

    input {
      font-size: 16px;
    }
  }

  .booking-bottom-buttons {
    display: flex;
    align-items: center;
    margin-top: 2em;
    flex-direction: column;

    @media only screen and (min-width: 641px) {
      flex-direction: row;
      margin-left: 1em;
    }

    .booking-continue {
      width: calc(100% - 32px);

      @media only screen and (min-width: 641px) {
        height: 62px;
        width: 300px;
        box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
        display: block;
      }
    }

    .booking-go-back {
      width: calc(100% - 32px);
      display: inline-block;

      @media only screen and (min-width: 641px) {
        display: block;
        width: 7rem;
        margin-left: 2em;
        --background: none;
      }
    }
  }
}

