.idv-questions {
  text-align: left;

  .idv-question-label {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
  }

  .idv-question:first-child {
    padding: 1em 0;
  }

  .idv-question {
    padding-top: 1.5em;
    padding-bottom: 1em;
    border-bottom: 1px solid #EEEEEE;
  }

  .idv-question:last-child {
    border-bottom: 0;
  }

  .callemmy-checkbox {
    display: flex;
    align-items: center;

    .idv-answer {
      color: $title-color;
      margin-left: 1em;
      font-size: 16px;
      line-height: 21px;
    }
  }

  .modal-bottom-buttons {
    text-align: center;
  }
}
