.card {
  margin-inline-start: 16px;
  margin-inline-end: 16px;
  display: flex;
  flex-direction: column;

  ion-card-header {
    display: flex;
    align-items: center;

    .title-subtitle {
      width: auto;
    }

    ion-card-subtitle {
      margin-top: 5px;
      text-align: left;
    }

    .callemmy-chip {
      width: auto;

      .callemmy-label {
        display: inherit;
      }
    }
  }

  .card-list {
    padding: 0px;
  }

  .with-border {
    border-bottom: 1px solid #f5f5f6;
  }

  .without-border {
    border-bottom: none;
  }

  .with-padding {
    padding: 30px;
  }

  .card-footer {
    border-top: 1px solid #eeeeee;
    padding: 5px 20px;
  }
}

.align-photo-center {
  .callemmy-avatar {
    margin: auto;
  }
}
