.who-to-book {
  font-family: $font;
  outline: none;

  .steps {
    text-transform: uppercase;
    color: $secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  h1 {
    color: $primary;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 21px;
  }

  .shortlist-select {
    text-align: left;

    .card-content-title {
      font-size: 16px;
      line-height: 20px;
    }

    p {
      color: $title-color;
    }

    .user-shortlists {
      display: flex;
      flex-direction: column;
      margin-top: 1.5em;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 1.5em;

      .checkbox {
        ion-label {
          text-align: left;
          color: $title-color;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
        }
      }

      .input-label {
        margin-top: 1em;
        margin-bottom: 1em;
      }

      .no-shortlists-yet {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $primary;

        a {
          color: $secondary;
          font-weight: bold;
        }
      }

      .shortlist-members-quantity {
        .checkbox-shortlists {
          flex: 0 0 31px;
          width: 31px;
          height: 31px;
          margin-bottom: 1em;
        }

        .checkbox-label-wrap {
          display: flex;
          flex-direction: column;
          background: none;
          border: 0;

          .shortlist-name {
            text-align: left;
            font-size: 16px;
            line-height: 15px;
            color: $primary;
            margin-bottom: 0;
            margin-left: 1em;
            font-weight: normal;
          }

          .shortlist-quantity {
            font-size: 13px;
            color: $title-color;
            margin-left: 1.2em;
            font-weight: normal;
            margin-bottom: 0;
            margin-top: 3px;
          }
        }
      }
    }

    .information-box {
      margin-top: 2em;
    }
  }

  ion-button {
    width: calc(100% - 32px);
  }

  .button-link {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #908e9e;
  }
}
