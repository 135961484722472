.booking-about-you {
  .callemmy-label {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: $label-color;
  }

  ion-label {
    font-weight: normal;
  }

  .callemmy-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;

    .checkbox-text {
      color: $title-color;
      margin-left: 0.5em;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      text-align: left;
    }
  }

  .button-link {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #908E9E;
  }

  .info-id-check {
    .info-content {
      display: flex;
      flex-direction: column;

      .callemmy-button {
        margin-top: 1.5em;
        height: 34px;
        font-size: 13px;
        line-height: 13px;
        --border-radius: 6px;
        --background: #605a8e;
        --background-activated: #605a8e;
        --background-focused: #605a8e;

        @media only screen and (min-width: 641px) {
          width: 250px;
          margin-top: 1.5em;
        }

        .callemmy-icon {
          color: white;
          font-size: 24px;
        }
      }
    }
  }
}
