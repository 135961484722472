.callemmy-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  border: none;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);

  .callemmy-icon {
    font-size: 48px;
    color: red;
  }
}
