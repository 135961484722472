@import './global/variables';
.chat-modal {
  .modal-wrapper {
    @media only screen and (min-width: 641px) {
      height: 80vh;
    }
  }

  .modal-content {
    .str-chat {
      height: 100%;
      background-color: white;

      &__message-simple-status {
        display: none;
      }

      &__li {
        text-align: left;

        &--top,
        &--single {
          margin-top: 30px;
          .str-chat__message {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            .str-chat__avatar {
              display: flex;
              visibility: visible;
              margin-right: 0;
              flex: 0 0 50px;
              flex-basis: 50px !important;
              width: 50px !important;

              &-fallback {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primary;
              }
            }
          }
        }
      }

      &__avatar {
        display: none;
      }

      &__message {
        width: 100%;

        &--me {
          flex-direction: column-reverse !important;
          align-items: flex-end !important;

          .str-chat__message-inner {
            margin-left: 0;
          }

          .str-chat__avatar {
            margin-left: 0;
          }
        }
      }

      &__message-inner {
        margin-right: 0;
      }

      &__message-text {
        margin-top: 15px;
      }

      &__message-text-inner {
        background: #ffffff;
        border: 2px solid #908e9e;
        box-sizing: border-box;
        box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
        border-radius: 0px 15px 15px 15px;
        padding: 20px;
      }

      &__message-simple__actions {
        display: none;
      }

      &__message-attachment {
        margin-top: 15px !important;
      }

      &__small-message-input {
        height: 62px;
      }

      &__textarea {
        min-height: 62px;

        textarea {
          min-height: 62px !important;
          display: block;
          margin: auto;
          font-size: 14px;
          line-height: 14px;
          color: $text-color;
        }
      }

      &__send-button {
        svg {
          height: 25px;
          width: 25px;
          path {
            fill: $tertiary;
          }
        }
      }
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    .modal-title {
      margin: auto;
    }
  }
  .close-modal {
    background: none;
    border: 0;
    padding: 0;
    padding: 16px 0;

    ion-icon {
      color: $label-color;
      height: 24px;
      width: 24px;
    }
  }

  .close-chat-modal {
    width: 90px;
    height: 34px;
    font-size: 13px;
    line-height: 16px;
  }
}
