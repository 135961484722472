.subscription {
  background-color: $content-background-color;

  div.content {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 641px) {
      justify-content: center;
      padding-top: 1.5em;
    }
  }

  .subscription-card {
    box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
    border-radius: 5px;
    max-width: 340px;
    height: 495px;

    .subscription-card-header {
      justify-content: center;
      min-height: 42px;

      ion-card-title {
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 21px;
        text-transform: capitalize;

        .callemmy-chip {
          margin-left: 20px;
          padding: 15px;

          .callemmy-label {
            margin-bottom: 0;
          }
        }
      }
    }

    .subscription-card-content {
      padding: 0;

      h3 {
        padding: 20px;
        border-bottom: 1px solid #eeeeee;
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 21px;
        color: $title-color;
      }

      div {
        padding: 25px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;

        ul {
          list-style: none;
          padding-left: 0;
          margin-top: 0;

          li {
            display: flex;
            align-items: flex-start;
            padding: 6px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: $primary;
            text-align: left;

            p {
              font-size: 14px;
              line-height: 18px;
              color: $primary;
            }

            .callemmy-icon {
              margin-top: 1px;
              margin-right: 10px;
              font-size: 16px;
              min-width: 16px;
            }
          }
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 14px;
          text-align: center;
          color: #908e9e;
        }
      }

      &__action-button {
        padding: 20px;
        border-top: 1px solid #eeeeee;
        text-align: center;

        .callemmy-button {
          height: 62px;
          width: 295px;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 15px;
          text-align: center;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          --border-radius: 6px;
        }

        .current-plan {
          box-sizing: border-box;
          --background: white;
          --border-width: 2px;
          --border-color: #d4d6e0;
          --border-style: solid;
          --color: #d4d6e0 !important;
        }
      }
    }

    @media only screen and (max-width: 400px) {
      div.subscription-card-content__action-button {
        padding: 7%;
      }

      .subscription-card-content__action-button .callemmy-button {
        width: 215px;
        font-size: 12px;
      }
    }
  }

  .basic-card {
    border-top: 9px solid #605a8e;

    ion-card-title {
      color: $primary;
    }

    .subscription-card-content {
      div {
        min-height: 175px;
      }
    }

    .callemmy-icon {
      color: #605a8e;
    }

    .callemmy-button {
      --color: white;
    }
  }

  .premium-card {
    border-top: 9px solid $secondary;

    ion-card-title {
      color: #d96452;

      .callemmy-chip {
        border: 2px solid $secondary;
      }
    }

    .subscription-card-content {
      div {
        min-height: 100px;
      }
    }

    .callemmy-icon {
      color: $secondary;
    }

    .callemmy-button {
      --background: #eb7c6b;
    }
  }

  .enterprise-card {
    border-top: 9px solid #729a8a;

    ion-card-title {
      color: #729a8a;
    }

    .subscription-card-content {
      div {
        min-height: 175px;
      }
    }

    .callemmy-icon {
      color: #729a8a;
    }

    .callemmy-button {
      --background: #729a8a;
    }
  }
}

.subscription-upgrade {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    color: #3f3968;
  }

  .confirmation-info {
    .from-to {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 20px;
        border-bottom: 1px solid #eeeeee;

        span {
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 15px;
          color: rgba(63, 57, 104, 0.5);
        }

        span+span {
          opacity: 1;
          color: $primary;
        }

        .total-value {
          display: flex;
          align-items: center;
          font-size: 18px;
          line-height: 18px;
        }
      }

      li:last-child {
        border-bottom: 0;
      }
    }
  }

  .credit-card {
    text-align: left;

    .callemmy-label {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      color: $label-color;
      margin-bottom: 1em;
    }

    .callemmy-button {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      text-decoration-line: underline;
      text-decoration-color: #d96452;
      width: 150px;
      margin-bottom: 0;
      height: 18px;
      --color: #d96452;
    }

    .callemmy-button-input {
      .callemmy-button {
        width: 100%;
        height: initial;
        text-decoration-color: $label-color;
      }
    }

    .callemmy-checkbox {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $title-color;
      margin-top: 2em;
      margin-bottom: 1.5em;

      .flexible-checkbox-label {
        margin-left: 20px;
      }

      .primary-card {
        font-size: 13px;
        color: #aaaaaa;
        margin-left: 0.5em;
      }
    }
  }

  .booking-bottom-buttons {
    display: flex;
    align-items: center;
    margin-top: 2em;
    flex-direction: column;

    @media only screen and (min-width: 641px) {
      flex-direction: row;
      margin-left: 1em;
    }

    .booking-continue {
      width: calc(100% - 32px);

      @media only screen and (min-width: 641px) {
        height: 62px;
        width: 300px;
        box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
        display: block;
      }
    }

    .booking-go-back {
      width: calc(100% - 32px);
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      --color: #989aa2;

      @media only screen and (min-width: 641px) {
        display: block;
        width: 100px;
        margin-left: 2em;
        --background: none;
      }
    }
  }
}

.subscription-success {
  @media only screen and (min-width: 641px) {
    background-color: #605a8e;
    min-height: 85vh;
    position: relative;
    padding: 0;
  }

  &__people-background {
    position: absolute;
    bottom: 0;
    height: 60vh;
    width: 100%;
    margin-left: -7em;
    display: flex;
    justify-content: center;

    img {
      position: absolute;
      width: 50em;
      bottom: -3em;
      left: -10em;
      transform: scale(1.3);

      @media only screen and (min-width: 641px) {
        bottom: -155px;
        transform: scale(1.5);
        left: initial;
      }
    }
  }

  h1 {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
    padding: 0 30px;
    margin-top: 20vh;
    margin-bottom: 1.5em;
  }

  p {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 0 35px;
    margin-bottom: 1.5em;
  }

  .callemmy-button {
    height: 52px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.5px;
    --border-radius: 6px;
  }
}

.enterprise-plan_modal {
  .mini-modal-header {
    border-top: 9px solid #729a8a;
  }

  .mini .mini-modal-header .modal-title {
    width: 100%;
    padding-left: 11px;
  }

  .textarea-wrapper {
    height: 98px;

    textarea {
      height: 98px;
    }
  }

  div.modal-wrapper {
    height: 450px;
  }

  div.modal-bottom-buttons {
    padding: 1em 0;

    .callemmy-button {
      font-family: $font;
      font-size: 16px;
      height: 40px;
      width: 100%;
    }
  }
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 10px 0 0;

  span {
    color: $text-color;
  }
}