.no-show-modal {
  .modal-content {
    display: flex;

    p {
      color: $text-color;
      line-height: 25px;
      margin: auto;
    }
  }

  .modal-bottom-buttons {
    .callemmy-button {
      width: 200px;
    }

    .callemmy-button + .callemmy-button {
      margin-left: 0;
    }
  }
}
