.error-message {
  display: flex;
  margin: auto;
  margin-bottom: 1.5em !important;
  margin-top: -0.5em !important;

  .all-error-messages {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .message {
    color: $error-message-color;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
  }

  .callemmy-icon {
    color: $error-message-color;
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 1em;
  }
}

.positive-margin-top {
  margin-top: 1.5em !important;
}
