.provider-profile {
  .callemmy-card {
    margin-top: 20px;
  }

  .callemmy-avatar {
    margin: auto;
    margin-bottom: 1em;
  }

  .provider-name {
    text-align: center;
    color: $primary;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-top: 1em;
  }

  .reviews-link {
    text-decoration: none;
  }

  .callemmy-rating {
    justify-content: center;

    span {
      font-weight: 500;
    }
  }

  .description {
    margin-top: 1em;
    color: $title-color;
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    height: fit-content;
    overflow: hidden;
  }

  .hourly-rate {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.5px;
    color: $primary;
    margin-top: 1em;
  }

  .upgrade-subscription {
    width: 60%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: rgba(63, 57, 104, 0.5);
    margin: auto;
    margin-top: 12px;

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      text-decoration-line: underline;
      color: rgba(63, 57, 104, 0.5);
      margin-top: 0;
    }

    a:visited {
      color: rgba(63, 57, 104, 0.5);
    }
  }

  .add-to-shortlist {
    height: 35px;
    margin: auto;
    margin-top: 1.5em;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    --padding-start: 1.5em;
    --padding-end: 1.5em;

    .callemmy-icon {
      margin-right: 0.5em;
    }
  }

  .add-to-other-shortlist {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    text-decoration-line: underline;
    color: #605A8E;
    text-transform: none;
    height: 15px;
    margin-top: 1em;
  }

  .card-subtitle {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: $title-color;
    margin: 0 -20px;
    margin-top: 3em;
    padding: 0 20px;
    padding-top: 25px;
    border-top: 1px solid #EEEEEE;
  }

  .provider-info {
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      text-align: center;
      padding: 20px;
      border-bottom: 1px solid #eeeeee;

      span {
        width: 40%;
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        color: rgba(63, 57, 104, 0.5);
        display: flex;
        align-items: center;
      }

      span+span {
        opacity: 1;
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
        color: $primary;
      }
    }

    li:last-child {
      border-bottom: 0;
    }
  }

  .callemmy-map {
    margin-top: 1em;
  }

  .provider-skills {
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      padding: 20px;
      border-bottom: 1px solid #eeeeee;

      span {
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        color: rgba(63, 57, 104, 0.5);
        display: flex;
        align-items: center;
      }

      span+span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $primary;
        margin-top: 10px;
      }
    }

    li:last-child {
      border-bottom: 0;
    }
  }
}

@media only screen and (min-width: 641px) {
  .provider-profile {
    .description {
      max-width: 800px;
      margin: 20px auto;
    }
  }
}
