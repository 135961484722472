.bookings-list {
  color: $primary;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  font-family: $font;

  ion-card-title {
    font-weight: 500;

    @media only screen and (min-width: 640px) {
      font-size: 18px;
    }
  }

  .booking-card--title {
    height: 16px;

    @media only screen and (min-width: 640px) {
      font-size: 18px;
    }
  }

  .card-footer {
    .dashboard-bookings-card-footer-button {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
      text-decoration-line: underline;
      color: $primary;
    }
  }

  .has-chat {
    @media only screen and (max-width: 640px) {
      flex-direction: column;
    }
  }

  .chat {
    position: relative;
    width: 100%;

    @media only screen and (max-width: 640px) {
      margin-top: 1rem;
    }

    @media only screen and (min-width: 641px) {
      height: 35px;
      width: auto;
      font-size: 13px;
    }

    .booker-chat_container {
      display: flex;
      justify-content: center;
      border-radius: 10px;
      height: 18.67px;
      width: 26px;
      background-color: $secondary;
    }

    &_btn {
      --box-shadow: none;
      --font-style: normal;
      --font-weight: bold;
      font-size: 13px;
      --font-size: 13px;
      --line-height: 13px;
      --border-radius: 6px;
      --letter-spacing: 0.5px;
      margin: 0 auto;
      width: 100%;
      height: 34px;
    }

    &_container {
      display: flex;
      justify-content: center;
      position: absolute;
      right: -0.5rem;
      bottom: 28px;
      height: 18.67px;
      width: 26px;
      border-radius: 10px;
      background-color: $secondary;

      .chat-messages--num {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.mobile-chat--bubble {
  position: relative;
  top: 19px;
  right: 8px;
}

.current-chat {
  margin-top: 20px;
}

.booking-row {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
  padding: 20px 20px;

  &:last-child {
    border-bottom: 0;
  }

  @media only screen and (min-width: 640px) {
    min-height: 83px;
  }

  .booking-date {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    text-align: left;
    width: 190px;
    font-family: $font;

    .date {
      color: #605a8e;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 5px;

      @media only screen and (min-width: 640px) {
        font-size: 18px;
      }
    }

    .duration {
      color: $title-color;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 16px;
    }
  }
}

.provider-opportunity-row {
  .booking-date {
    width: auto;
  }
}

.booker-bookings--list {
  .booking-row--right {
    position: relative;
    display: flex;
    flex-direction: column;

    .view-booking-button {
      height: 35px;
      font-size: 13px;
      line-height: 13px;
      --border-radius: 6px;
    }

    .fullpage-chat--bubble {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 17px;
      right: -15px;
      width: 34px;

      .chat-messages--num {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        padding-top: 1px;
        text-align: center;
      }
    }
  }

  .booking-row:last-child {
    border-bottom: 0;
  }

  .chat-and-phone-btns {
    padding: 0;

    .chat {
      margin-top: 1rem;

      @media only screen and (min-width: 640px) {
        width: 180px;
      }
    }
  }

  .remove-height {
    min-height: 0;
  }

  .button-link {
    color: #605a8e;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    --padding-start: 0;
  }

  .person-outline {
    display: flex;
    justify-content: center;
    background-color: #D4D6E0;
    border-radius: 36px;
    width: 43px;
    height: 43px;

    .person {
      margin: auto;
      height: 25px;
      width: 25px;
    }

    @media only screen and (max-width: 640px) {
      height: 34px;
      width: 34px;

      .person {
        height: 21px;
        width: 21px;
      }
    }
  }

  .booking-row--inprogress,
  .booking-row {
    display: flex;
    justify-content: space-between;
    align-self: flex-start;

    .clickable-row {
      width: 100%;
      background-color: transparent;
      border: none;
      padding: 0;
    }

    .booking-row--left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
    }
  }

  div.booking-row--current.booking-row {
    border-bottom: none;
    height: auto;
    padding: 0;

    @media only screen and (max-width: 640px) {
      height: auto;

      .no-wrap-row {
        flex-wrap: nowrap;
      }
    }

  }
}

.bookings_card ion-card-header,
.card ion-card-header {
  display: flex;
  justify-content: space-between;

  .subtitle {
    margin-top: 0;

    .live_btn {
      width: 110px;
      margin-top: 0;
      margin-bottom: 0;

      ion-label {
        left: 15%;
      }
    }
  }
}

@media only screen and (min-width: 640px) {

  .bookings_card ion-card-header {
    height: fit-content;
  }

  .upcoming-booking-row {
    min-height: 120px;
  }
}

.footer-text {
  color: $primary;
  font-family: $font;
  background-color: transparent;
  border: none;
  margin: 10px 0;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.booker-current-booking-card {
  margin-top: 30px;
}

div.parent-ellipses {
  margin-bottom: 15px;
}