.credit-card-manager {
  height: 100%;

  .credit-card-manager-title {
    margin-top: 1em;
    margin-bottom: 2em;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $text-color;
  }

  .callemmy-checkbox {
    margin: 1em 0;
    display: flex;
    align-items: center;

    .flexible-checkbox-label {
      color: $title-color;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      margin-right: 0.5em;
    }

    .primary-address {
      font-size: 12px;
      color: #aaaaaa;
    }

    .address-label {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 1em;

      .callemmy-button {
        height: 15px;
        width: 100%;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        --padding-start: 0;
        --color: #aaaaaa;

        span {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
  }

  .button-input-link {
    margin-bottom: -0.5em;
    --padding-start: 0.5em;
  }

  .credit-card-manager-bottom-buttons {
    .apply-button {
      --color: $secondary;
    }

    .delete-button {
      display: flex;
      align-items: center;
      margin: auto;
      margin-left: auto !important;
      width: 70%;
      margin-top: 0.5em;
      color: #908e9e;
      font-size: 13px;
      --background: none;
      --background-color: transparent;
      --ripple-color: transparent;
      --border: none;
      --box-shadow: none;

      .callemmy-icon {
        color: $label-color;
        background: transparent;
      }
    }
  }
}
