.question-providers {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;

  p {
    font-family: $font;
    font-size: 18px;
    line-height: 24px;
    color: $title-color;
  }

  .callemmy-button {
    margin-right: 5px;
  }

  .callemmy-button + .callemmy-button {
    margin-left: 5px;
  }

  .button-link {
    color: $text-color;
  }
}
