.callemmy-tooltip {

  div.popover-content {
    border-radius: 5px;

    @media only screen and (max-width: 640px) {
      min-width: 97vw;
    }

    .popover-viewport {
      padding: 20px;

      .popover-title {
        text-align: left;
        margin: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: $primary;
      }

      .popover-content {
        text-align: left;
        font-weight: normal;
        font-style: normal;
        font-size: 13px;
        line-height: 18px;
        color: $primary;

        a {
          color: $secondary;
          font-weight: bold;
        }
      }
    }
  }
  .question-button {
    border: none;
    background: none;
  }
}
