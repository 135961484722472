.booking-details {
  .has-secondary-color {
    text-decoration-color: $secondary !important;

    span {
      color: $secondary !important;
      font-weight: bold !important;
    }
  }

  .flexible-checkbox-label {
    color: $title-color;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
  }

  .special-requirements-label {
    font-family: $font;
    margin-bottom: 1em;
    color: $label-color;
    font-size: 15px;
    height: 15px;
    overflow: unset;
    font-weight: 600;
  }

  .button-link {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #908e9e;
  }

  .callemmy-label {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: $label-color;
  }

  .user-addresses {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    .callemmy-checkbox {
      margin-left: 0.5em;
      margin-top: 1em;
      margin-bottom: 1em;
      display: flex;
      align-items: center;
    }

    .flexible-checkbox-label {
      margin-left: 1em;
      margin-right: 0.5em;
    }
  }

  .booking-details-form {
    text-align: left;

    .toggle-single-vs-multi-container {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      align-items: baseline;

      @media only screen and (min-width: 641px) {
        display: flex;
        justify-content: flex-start;

        .toggle-single-vs-multi {
          margin-left: 20px;
        }
      }
    }

    .multi-line-height {
      line-height: 1.5;
    }

    .button-input-link {
      background: transparent;
      --ripple-color: transparent;
    }

    .select-rate-range {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 768px) {
        flex-direction: column;

        .callemmy-select {
          width: 100%;
        }
      }

      .update-subscription-button-link {
        color: $secondary;
        white-space: normal;
        margin-top: 20px;

        @media only screen and (min-width: 768px) {
          margin-left: 2rem;
        }
      }

      .update-subscription-button-link::part(native) {
        line-height: 20px;
      }

      .reset-default {
        @media only screen and (min-width: 768px) {
          margin-left: 2rem;
          margin-top: 2rem;
        }
      }
    }

    .update-subscription-button-link,
    .manage-addresses-button {
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      text-decoration-color: $secondary;
      --padding-start: 0;
    }

    .primary-address {
      font-size: 12px;
      color: #aaaaaa;
    }

    .callemmy-select {
      select {
        color: $title-color;
      }
    }

    .desktop-callemmy-select {
      text-align: left;
      width: 48%;
    }

    .num-of-kids_select,
    .num-of-providers_select {
      margin-bottom: 24px;
    }

    .which-kid-is-this-booking {
      .callemmy-checkbox {
        margin-left: 0.5em;

        span {
          color: $title-color;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          display: flex;
          align-items: center;
          margin-left: 1em;
        }
      }

      .callemmy-checkbox + .callemmy-checkbox {
        margin-top: 1em;
      }

      .edit-family-settings-button {
        margin-top: 1em;
        --padding-start: 0;
      }
    }

    .booking-time-select {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 641px) {
        flex-direction: row;
        justify-content: space-between;

        .callemmy-picker {
          width: 48%;
          margin-top: 0;
        }
      }

      @media only screen and (max-width: 640px) {
        .callemmy-picker {
          margin-top: 0;
        }

        .callemmy-picker + .callemmy-picker {
          margin-top: initial;
        }
      }
    }
  }
}

.content {
  .content_card--content {
    .details_list {
      li {
        .list-wrap_container,
        .total_container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        div.cbk-booking-statement {
          align-items: center;
          margin-top: 0;

          span {
            display: inline-block;
          }

          span.bold_text {
            color: $primary;
            font-weight: bold;
          }
        }

        .booking-detail-details_container {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }

        span {
          width: 50%;
          padding: 0;
          display: flex;
          align-items: center;

          .statement-text {
            flex-direction: row;
          }
        }

        .italic_span {
          font-style: italic;
          font-size: 12px;
          padding-top: 5px;
        }
      }
    }
  }
}

div.desktop-select-disabled div.desktop-select__placeholder,
div.select-text.select-placeholder.disabled-select-placeholder {
  color: #aaaaaa;
}
