.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2em 40px 2em 40px;
  box-sizing: border-box;

  @media only screen and (max-height: 650px) {
    padding: 1em 40px 1em 40px;
  }

  @media only screen and (min-width: 640px) {
    height: calc(100vh - 117px);
    background-color: $primary;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 2em 117px 2em !important;
  }

  @media only screen and (min-width: 1024px) {
    padding: 0 6em 117px 6em !important;
  }

  .error-image {
    height: 14.2em;
    width: 18.5em;

    @media only screen and (max-height: 650px) {
      height: 10.22em;
      width: 13.32em;
    }

    @media only screen and (min-width: 640px) {
      width: 20.8em;
      height: 16em;
    }

    @media only screen and (min-width: 1024px) {
      width: 26em;
      height: 20em;
    }
  }

  .error-text {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    @media only screen and (max-height: 650px) {
      height: 55%;
    }

    @media only screen and (min-width: 640px) {
      width: 40%;
      height: 20em;
      align-items: flex-start;
      margin-bottom: 0;
    }

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 34px;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0;
      color: var(--ion-color-primary-contrast);
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      text-align: justify;
      color: var(--ion-color-primary-contrast);
      margin-top: 0;
      margin-bottom: 0;

      @media only screen and (min-width: 640px) {
        font-size: 18px;
        line-height: 20px;
      }
    }

    ul {
      list-style: none;
      margin-top: 0px;
      padding-left: 0;

      li {
        margin-top: 0.5em;

        @media only screen and (min-width: 640px) {
          text-align: left;
        }

        a {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          font-feature-settings: 'salt' on, 'liga' off;
          color: var(--ion-color-primary);
        }
      }
    }
  }
}
