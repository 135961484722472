.card {
  margin-inline-start: 16px;
  margin-inline-end: 16px;

  ion-card-header {
    display: flex;
    align-items: center;

    .title-subtitle {
      width: auto;
    }

    ion-card-subtitle {
      margin-top: 5px;
      text-align: left;
    }

    .callemmy-chip {
      width: auto;

      .callemmy-label {
        display: inherit;
        --color: #3f3968;
      }
    }
  }

  .card-content {
    font-size: 16px;
    color: $primary;
    padding: 30px 20px;

    p {
      font-size: 16px;
    }

    .bold-text {
      font-weight: bold;
    }

    .link-text {
      color: $primary;
      font-weight: bold;
    }
  }

  .card-list {
    padding: 0px;
  }

  .with-border {
    border-bottom: 1px solid #f5f5f6;
  }

  .without-border {
    border-bottom: none;
  }

  .with-padding {
    padding: 30px;
  }

  .card-footer {
    border-top: 1px solid #eeeeee;
    padding: 5px 20px;
  }
}