.cancel-modal {

  .modal-wrapper {
    height: 364px !important;
  }
}

.cancel-modal,
.provider-cancel-modal {
  .reconcile-booking-times-modal {
    .modal-bottom-buttons {
      padding: 1em 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .apply-button {
        width: auto;
      }

      .close-modal {
        color: $text-color;
        text-transform: capitalize;
      }
    }
  }

  .cancel-booking-modal {
    height: 100%;
    display: flex;
    flex-direction: column;

    .modal-content {
      flex-grow: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      p {
        text-align: left;
        margin-top: 0;
        line-height: 22px;
      }

      .callemmy-select {
        margin-top: -20px;
        width: 100%;

        ion-label {
          margin-bottom: 5px;
        }
      }

      .information-box {
        margin-bottom: 0;
      }

      .other-reason-textbox {
        font-size: 16px;
        min-width: 285px;
        margin-bottom: 0;

        &__label {
          display: flex;
          justify-content: space-between;
          margin: 0px 0 5px;

          button.close-modal {
            background-color: transparent;
            border: none;

            ion-icon {
              color: #D9D7E0;
              font-size: 16px;
            }
          }
        }
      }
    }

    .modal-bottom-buttons {
      padding: 1em 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .apply-button {
        width: auto;
      }

      .close-modal {
        color: $text-color;
        text-transform: capitalize;
      }
    }
  }
}

.provider-cancel-modal {
  .modal-wrapper {
    height: 500px;
  }

  .information-box {
    margin-top: 0;
  }

  .callemmy-text-area {
    margin-top: 30px;
    width: 100%;

    .textarea-element {
      margin-bottom: 0;
    }
  }
}

.provider-cancel-modal.provider-booking-details-modals .modal-wrapper {
  height: 570px;
}