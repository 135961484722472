.references {
  .references-checkbox-label {
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: $label-color;
    text-align: left;
    margin-left: 1em;
    margin-top: 0.1em;
  }
}

.references {
  .references-label {
    color: #908e9e;
  }

  .back-button {
    font-size: 13px;
    line-height: 21px;
    font-weight: normal;
    color: #605A8E;
    text-decoration: none !important;
    border-width: 0;
    margin: 0;
    height: 15px;
    --padding-start: 0;
    --padding-end: 0;


    span {
      font-weight: normal;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
}
