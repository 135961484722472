.collapsed-content {
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    border-outline: none;

    .callemmy-label {
      color: $label-color;
      width: 90%;

      span {
        position: relative;
        top: 1px;
        font-weight: normal;
        font-size: 13px;
        margin-left: 0.5em;
      }
    }

    .chevron-icon {
      position: relative;
      display: block;
      width: 5%;
      height: 14px; /*height should be double border*/
      margin-left: auto;
      margin-right: 0;
    }

    .chevron-icon-down {
      margin-top: 5px;
    }

    .chevron-icon-up {
      margin-top: -3px;
    }

    .chevron-icon::before,
    .chevron-icon::after {
      position: absolute;
      display: block;
      content: '';
      border: 7px solid transparent; /*adjust size*/
    }

    .chevron-icon-up::before {
      bottom: 0;
      border-bottom-color: $label-color; /*Chevron Color*/
    }

    .chevron-icon-up::after {
      bottom: -2px; /*adjust thickness*/
      border-bottom-color: #fff; /*Match background colour*/
    }

    .chevron-icon-down::before {
      top: 0;
      border-top-color: $label-color; /*Chevron Color*/
    }

    .chevron-icon-down::after {
      top: -2px; /*adjust thickness*/
      border-top-color: #fff; /*Match background colour*/
    }
  }
}
