.add-credit-card {
  height: 100%;

  .modal-content {
    .credit-card-error {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0.5em !important;
    }
  }

  .callemmy-label {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: $label-color;
  }

  .callemmy-text-input {
    height: 62px;
  }

  .half-input {
    width: 45%;
    display: inline-block;
  }

  .half-input + .half-input {
    margin-left: 10%;
  }

  .callemmy-checkbox {
    margin-top: 0em;
  }

  .stripe-form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .form-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .stripe-input {
        width: 45%;
      }
    }

    .stripe-input {
      width: 100%;
      display: flex;
      flex-direction: column;

      .stripe-card-name {
        input {
          height: 62px;
          color: $label-color;
          font-weight: 400;
          font-size: 16px;
        }
      }

      .StripeElement {
        border: 1px solid #d4d6e0;
        height: 62px;
        font-size: 32px;
        padding: 0 22px;
        color: $label-color;
        box-sizing: border-box;
        border-radius: 6px;
      }

      .StripeElement--invalid {
        border: 1px solid $error-message-color;
      }

      .error-message {
        margin-left: 0;
        margin-bottom: 0 !important;
      }
    }
  }
}
