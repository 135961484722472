.callemmy-checkbox {
  display: flex;
  margin: 0.5em 0;

  ion-checkbox {
    min-width: 26px;
    min-height: 26px;
  }

  .checkbox-label {
    font-family: $font;
    margin-left: 1em;
    margin-bottom: 0;
  }
}
