.information-box {
  display: flex;
  color: $primary;
  height: auto;
  border-style: solid;
  border-radius: 6px;
  border-width: thin;
  // min-height: calc(100vh - 60.5em);
  padding: 1em;
  margin: 1em 0;
  box-shadow: none;
  flex-direction: column;
  overflow: initial;

  ion-icon {
    display: flex;
    align-items: center;
    color: $primary;
    font-size: 21px;
  }

  .info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;

    &__close-button {
      height: 18px;
      display: flex;
      align-items: center;
      margin: 0;
      --padding-end: 0;

      .callemmy-icon {
        font-size: 16px;
        color: rgba(63, 57, 104, 0.2);
      }
    }

    ion-card-title {
      color: $primary;
      font-size: 16px;
      font-weight: bold;
    }

    .has-icon {
      margin-left: 0.5em;
    }
  }

  ion-card-content {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 15px;
    padding-top: 0;
    margin-top: 0.05em;
    text-align: left;

    a,
    .button-link {
      color: $secondary;
      font-weight: bold;
      background: none;
      font-size: 16px;
      padding: 0;
      text-decoration-color: $secondary;
    }

    .og-details-btn-link {
      color: $primary;
      text-decoration-color: $primary;
      margin-top: 5px;
    }
  }

  @media only screen and (max-width: 640px) {
    .info-header {
      ion-card-title {
        font-size: 14px;
      }
    }

    ion-card-content {
      font-size: 13px;

      a,
      .button-link {
        font-size: 13px;
      }
    }
  }
}

.warning {
  background-color: $info-color-desaturate;
  border-color: $info-color;
}

.with-margin {
  margin: 16px;
}