.card-content {
  &_button {
    width: 100%;
    height: 43px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
  }

  .textblock-padding_list {
    padding-left: 20px;
    text-align: left !important;
  }
}

#card-content-name {
  font-weight: bold;
}

.section-padding {
  padding-bottom: 3em;
}

.content {
  .content_card--content {
    .details_list {
      li {
        span {
          width: 50%;
          padding: 0;
        }

        .no-bottom-border {
          border-bottom: none;

          .booking-detail-details_title {
            min-width: 104.41px;
          }
        }
      }
    }
  }
}

.can-bring-kid-note-with-icon {
  display: flex;
  flex-direction: row;
  font-style: italic;
  color: $primary;
  font-size: 14px;
  border-bottom: 1px solid #eeeeee;
  padding: 0px 20px 15px;

  .cbk-icon-wrapper {
    display: flex;
    min-width: 104.41px;
  }

  img {
    width: 54px;
    height: 54px;
  }

  .can-bring-kid-note {
    color: $primary;
    margin-top: 2px;
    text-align: left;
  }
}
