.signin {
  background-color: $content-background-color;
  height: 100%;

  .information-box {
    margin-bottom: 0;

    @media only screen and (min-width: 641px) {
      display: flex;
      flex-grow: 4;
      flex-basis: 100%;
    }
  }

  .form {
    .card-content {
      background-color: #fff;
    }
  }

  @media only screen and (min-width: 641px) {
    margin-top: 1em;
  }

  .information-box {
    .info-header {
      justify-content: center;

      ion-card-title {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .content-container {
      .info-content {
        padding: 10px 0;
        font-weight: 500;
        line-height: 19px;
      }

      a {
        font-weight: 600px;
        font-size: 14px;
      }
    }
  }

  .signin-content {
    padding-top: 0.5em;
    padding-bottom: 2em;
    background-color: #f1f5f4;

    @media only screen and (min-width: 641px) {
      .flex-signin {
        display: flex;
        flex-grow: 4;
      }

      .social {
        width: 100%;
        max-height: 35%;
      }

      .form {
        background-color: #fff;
        width: 100%;
      }
    }
  }

  ion-card-title {
    font-weight: bold;
  }

  ion-button {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    height: 60px;

    ion-icon {
      color: $secondary;
    }
  }

  .social {
    ion-button {
      font-size: 15px;
      line-height: 15px;
      --border-width: 2px;
    }

    .social-button {
      border-radius: 6px;
    }

    .google-logo_button {
      color: #000;
      border: thin solid #888;
      box-shadow: 1px 1px 1px grey;
    }

    .fb-logo_button {
      background-color: #1877F2;
    }

    .apple-logo_button {
      color: #fff;
      background-color: #000;
    }

    ion-button+ion-button {
      margin-top: 20px;

      .fb-logo {
        width: 25px;
        height: 25px;
        background-color: #1877F2;
        color: #fff;
        z-index: 10;
      }

      .apple-logo {
        width: 25px;
        height: 25px;
        color: #fff;
      }
    }

    ion-icon {
      margin-right: 1rem;
    }

    ion-card-title {
      color: $title-color;
      font-weight: bold;
      text-align: left;
      font-size: 16px;
    }

    ion-card-content {
      color: $text-color;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      text-align: left;
      min-height: 500px;
    }
  }

  .form {
    height: auto;

    @media only screen and (min-width: 641px) {
      width: 100%;
    }

    ion-input {
      height: 62px;
    }

    ion-label {
      color: $primary;
    }

    ion-button {
      font-size: 18px;
      line-height: 18px;
    }

    .remember-checkbox {
      text-align: left;
      text-transform: capitalize;
      margin-bottom: 1.5em;

      ion-label {
        color: $title-color;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .button-link {
      height: 20px;
      margin-top: 20px;
      font-style: normal;
      font-weight: normal;
      color: $text-color;
      font-size: 13px;
      line-height: 20px;
      text-decoration: underline;
      text-transform: none;
      border: none;
      border-radius: 0;
    }

    .show-password {
      cursor: pointer;
      margin-top: -0.5em;
      margin-bottom: 2em;
      display: flex;
      align-items: center;
      background: none;
      padding: 0;
      border: 0;

      .callemmy-icon {
        color: $label-color;
        font-size: 24px;
        margin-right: 13px;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-decoration: underline;
        color: $label-color;
      }
    }
  }
}

.password-recovery-modal {
  .modal-bottom-buttons {
    display: none;
  }
}

div.content {
  @media only screen and (max-width: 640px) {
    .signin-content {
      padding-top: 0;

      ion-input {
        height: 45px;
        margin-bottom: 1em;
      }

      ion-label {
        margin-bottom: .5em;
      }

      ion-button {
        font-size: 18px;
        line-height: 18px;
        height: 45px;
      }

      .button-link {
        height: 30px;
      }

      .show-password {
        margin-bottom: .8em;
      }

      .card {
        background-color: #fff;
      }
    }
  }
}

.google-logo {
  height: 25px;
  width: 25px;
  margin-right: 1em;
}

.social_span--alignment {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 10px;
}

.social-button_text {
  width: 230px;
  text-align: left;
}
