.share-modal {
  display: flex;
  flex-direction: column;

  .modal-wrapper {
    height: 220px;
    width: 420px;

    @media only screen and (max-width: 640px) {
      width: 300px;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;

    .social-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
      margin-top: 1em;

      a {
        font-size: 14px;
        color: $title-color;
        margin-right: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        .callemmy-icon {
          font-size: 32px;
          color: $title-color;
        }
      }

      a+a {
        margin-left: 1em;
      }
    }

    ion-label.share-link_label {
      margin: 0 22px;
      font-size: 13px;
    }

    .copy-input-with-copy-btn {
      display: flex;
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;

      .callemmy-text-input {
        font-size: 13px;
        margin-bottom: 0;
        height: 42px;
        border-radius: 5px 0px 0px 5px;
        border-right: 0;
      }

      .callemmy-button {
        height: 42px;
        font-size: 13px;
        margin-left: 0em;
        border-left: 0;
        --border-radius: 0 5px 5px 0px;
        --border-color: $button-border;
        --border-width: 1px;
        --color: #729a81;
        --background: rgba(114, 154, 129, 0.1);
        --background-activated: #729a81;
        --background-focused: #729a81;
        --background-hover: #729a81;
        --color-activated: #729a81;
        --color-focused: #729a81;
        --color-hover: #729a81;
      }
    }
  }
}