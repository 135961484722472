.provider-onboarding-schedule-button {
  width: 90%
}

#schedules-go-back {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #908E9E;
}

.onboarding-schedules {
  .notification-settings-form {
    p {
      text-align: left;
    }
  }
}
