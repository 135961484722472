.more-about-error-message {
  @media only screen and (max-width: 640px) {
    margin-top: 0;
  }
}

.warning-modal-bring-kids-icon {
  position: absolute;
  max-width: 33px;
  border-radius: 50%;
}

.margin-left {
  margin-left: 40px;
}

.margin-right {
  margin-right: 5px;
}

.warning-modal-bring-kids-text span {
  position: relative;
  line-height: 1.5;
}