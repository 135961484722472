//Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Fat Frank';
  src: url('../fonts/FatFrank.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Martel Heavy';
  src: url('../fonts/martel-heavy.ttf');
}