.popover {
  position: absolute;
  padding: 20px 30px;
  min-width: 2em;
  z-index: 11;
  border-radius: 6px;
  visibility: hidden;
  box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);

  .popover-arrow {
    width: 20px;
    height: 20px;
    background-color: white;
    display: block;
    position: absolute;
    right: 20px;
    top: -0.4em;
    transform: rotate(45deg);
    border-radius: 6px;
  }
}

.popover-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  opacity: 0;
}
