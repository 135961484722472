.rating-link {
  text-decoration: none;
}

.rating {
  display: flex;
  align-items: center;

  ion-icon {
    color: $secondary;
  }

  span {
    position: relative;
    display: flex;
    margin-left: 0.5em;
    color: $secondary;
    font-family: $font;
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
  }
}

.callemmy-rating-bigger {
  ion-icon {
    color: $secondary;
    font-size: 20px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin-left: 1em;
  }
}

.review-link {
  font-size: 13px;
  color: $secondary;
  text-decoration: transparent;
}
