.bookings_card-content {

  .location-block {
    @include double-line_block;
  }

  .providerBooking-row {
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    flex-wrap: wrap;

    .clickable-row {
      width: 100%;
      background-color: transparent;
      border: none;
      padding: 0;
    }

    &--left {
      display: flex;
      align-items: center;
      position: relative;
    }

    .booking-row--right {
      display: flex;
      flex-direction: column;
    }

    .date-display_box {
      @include date_box;
    }

    .view-booking-button--small {
      font-size: 11px;
    }

    @media only screen and (min-width: 641px) {

      .date-display_section {
        padding-right: 1em;
      }

      .view-booking {
        &_text {
          font-weight: bold;
          font-size: 13px;
          line-height: 13px;
          padding-top: 8px;
        }
      }

      .view-booking-button {
        height: 35px;
        font-size: 13px;
        line-height: 13px;
        --border-radius: 6px;
        --background-hover: #729A8A;
        --color: #729A8A;
        --border-color: #729A8A;
      }
    }
  }

  .name-time--block {
    display: flex;
    justify-content: center;
    margin-left: 1em;


    &_container {
      color: $primary;
      font-family: $font;
      font-size: 15px;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .provider-name {
        font-weight: bold;
        margin-bottom: 5px;
      }

      .duration {
        color: $primary;
        font-weight: normal;
        margin-bottom: 5px;
        text-align: left;
      }

      .italic_span {
        font-style: italic;
      }
    }
  }

  .past-bookings-btn {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .number_btn {

    .number_btn--text {
      font-weight: bold;
      padding: 20px 40px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.5px;
      padding: 0px 40px;
    }
  }
}

.live_btn {
  border-radius: $border-radius;
  background: #D4D6E0;
  height: 18px;
  width: auto;
  width: 47px;
  color: #FFFFFF;

  ion-label {
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.5px;
    position: absolute;
    left: 19.4%;
    top: 19.4%;
    text-transform: uppercase;
  }
}

.searching_btn {
  border-radius: $border-radius;
  background: #D4D6E0;
  height: 18px;
  width: 120px;
  color: #FFFFFF;

  ion-label {
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.5px;
    position: absolute;
    left: 19.4%;
    top: 19.4%;
    text-transform: uppercase;
  }
}

.view-more_link {
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  text-decoration: none;

  p {
    color: $account-menu-color;
    text-decoration: underline;
  }

}

.bookings_card {
  box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
  border-radius: 5px;
  margin-top: 1em;

  ion-card-header {
    color: $title-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 21px;
  }

  .current-booking_title {
    font-weight: bold;
  }
}

.card-content.bookings_card-content {
  padding: 0;
}

.event-booking--text {
  font-size: 12px;
}

.providerBooking-row--current {
  border-bottom: 0;
  padding-bottom: 0px;
}

.chat-and-phone-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 95px;
}

.call_btn {
  --box-shadow: none;
  --font-style: normal;
  --font-weight: bold;
  --line-height: 13px;
  --border-radius: 6px;
  --letter-spacing: 0.5px;
  margin: 0 auto;
  width: 100%;
  height: 34px;
}

#call_button {
  font-size: 13px;
  margin-top: 10px;
  height: 34px;
}

.ellipses {
  align-self: flex-end;
  font-size: large;
  margin: 20px 20px 5px;
}

@media only screen and (min-width: 641px) {
  .call_btn {
    width: 180px;
  }

  .ellipses {
    visibility: hidden;
  }
}