.change-phone-modal {
  height: 100%;

  .callemmy-label {
    color: $label-color;
  }

  .callemmy-text-input {
    height: 62px;
  }
}
