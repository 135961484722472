.booking-shortcut {
  margin: 16px !important;

  @media only screen and (min-width: 641px) {
    height: 62px;
    max-width: 500px;
    box-shadow: 0 4px 9px rgba(63, 57, 104, 0.07);
    display: block;
  }
}
