.my-details-page {
  background-color: $backdrop-color;
  font-family: $font;
  padding-bottom: 2em;

  .headLine {
    margin-top: 20px;
  }

  .content {
    background-color: $content-background-color;
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .my-details-section {
    text-align: left;
    padding-bottom: 1em;

    .button-link {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      text-decoration-line: underline;
      text-decoration-color: $secondary;
      min-width: 5.5em;
      --padding-start: 0;
      --padding-end: 0;
      --color: #D96452;
    }

    .payout-range__label {
      display: flex;
      justify-content: space-between;
    }
  }

  .my-details-form {
    margin-top: 23px;
    border-width: 120%;
  }

  .my-details-address {
    margin-top: 27px;
  }

  h2 {
    text-align: left;
    color: $title-color;
    font-size: 16px;
    font-weight: bold;
    font-family: $font;
    line-height: 21px;
    font-style: normal;
    margin-bottom: 22px;
  }

  .margin {
    margin-top: 27px;
  }

  .callemmy-label {
    color: $label-color;
    font-size: 15px;
    font-weight: bold;
    line-height: 15px;
    font-style: normal;
  }

  .callemmy-text-input {
    height: 62px;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: $title-color;
  }

  a {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: $secondary;
  }

  .my-details-save-button {
    width: calc(100% - 32px);
    height: 62px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    margin: auto;
    margin-top: 16px;

    @media only screen and (min-width: 641px) {
      height: 62px;
      width: 300px;
      box-shadow: 0px 4px 9px rgba(63, 57, 104, 0.07);
      margin-left: 16px;
      margin-right: 100%;
    }
  }

  .error-message {
    margin-top: 1em !important;
  }
}

.my-details-page-provider {
  padding-bottom: 50px;

  .error-message {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.callemmy-checkbox,
.payout-checkbox {
  span.checkbox-text {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: $title-color;
    margin-left: 1em;
  }
}

.info-btn-link {
  color: $primary;
}