.footer {
  position: relative;
  bottom: 0;
  background-color: $primary;
  padding-top: 40px;
  width: 100%;

  .footer-container {
    padding: $footer-padding;
    display: flex;
    justify-content: center;
  }

  .footer-items {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    ul {
      list-style: none;

      li {
        font-style: normal;
        font-weight: bold;
        text-align: left;
        padding: 10px;
        box-sizing: border-box;
        p{
          font-family: Fat Frank, sans-serif;
          margin:0 0 1em 0;
        }
        a, p {
          color: white;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-decoration: none;
        }

        &.link {
          a {
            padding: 12px 24px;
            box-sizing: border-box;
            border-radius: 20px;
            background-color: $secondary;
            font-family: $font;
            line-height: 18px;
            margin-bottom: 1em;
            font-size: 13px;
            overflow: unset;
            font-weight: 400;

            &:hover {
              background-color: $button-hover;
            }
          }
        }
      }

      li+li {
        font-weight: normal;
        margin-bottom: 0.5em;
        text-transform: uppercase;

        a {
          line-height: 19px;
          font-size: 14px;
          color: white;
          font-weight: 500;
        }
      }
    }
  }

  .footer-social-networks {
    width: 100%;
    margin-top: 2em;
    text-align: center;
    font-style: normal;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 19px;
    color: white;

    .title {
      font-family: Fat Frank, sans-serif;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-decoration: none;
    }

    .icons {
      display: flex;
      justify-content: center;

      a {
        margin: 30px 20px;
        text-decoration: none;
        color: white;

        &:hover {
          color: $secondary;
        }
      }
    }
  }

  .footer-rights {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 19px;
    color: white;

    a {
      text-decoration: none;
    }
  }
}